<template>
  <div>
    <el-upload
      ref="upload"
      class="upload-demo"
      :action="uploadUrl"
      :headers="headers"
      :multiple="true"
      :name="name"
      :show-file-list="showFileList"
      :accept="accept"
      :on-success="onSuccess"
      :on-error="onError"
      :before-upload="beforeUpload"
      :list-type="listType"
      :http-request="uploadFile"
      :limit="limit"
      :on-exceed="onExceed"
      :disabled="disabled"
      :file-list="FileList"
      :key="name + this.index"
    >
      <template #default>
        <slot></slot>
      </template>
      <template #tip>
        <slot name="tip"></slot>
      </template>
    </el-upload>
    <div :key="fileLists.length">
      <div class="fileTemp" v-for="file in FileList" :key="file.uid">
        <div>
          <span @click.self="onPreview(file.name)"
            ><i class="el-icon-document" style="margin-right: 5px"></i>
            {{ file.name }}</span
          >
          <span>
            <i class="el-icon-upload-success el-icon-circle-check"> </i>
            <i class="el-icon-close" @click.self="onClose(file.name)"></i
          ></span>
        </div>
        <el-progress
          v-show="echoFlag || file.REFS"
          :percentage="file.percentage"
        ></el-progress>
      </div>
    </div>
    <el-image-viewer
      style="z-index: 9999 !important"
      v-if="imgViewerVisible"
      :on-close="
        () => {
          imgViewerVisible = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
import { getTokenCookie } from "@/utils/cookies";
import request from "@/utils/request";
export default {
  name: "uploadView",
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  props: {
    action: {
      type: String,
    },
    multiple: {
      default: () => true,
      type: Boolean,
    },
    name: { type: String, default: () => "file" },
    showFileList: { type: Boolean, default: () => false },
    accept: { type: String },
    listType: { type: String, default: () => "text" },
    autoUpload: { type: Boolean, default: () => true },
    limit: { type: Number, default: () => 5 },
    disabled: { type: Boolean, default: () => false },
    fileList: { type: Array, require: true },
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + "/share/oss/fileUpload",
      headers: { Authorization: getTokenCookie() },
      imgList: [],
      imgViewerVisible: false,
      i: 1,
      flag: null,
      index: 0,
      fileLists: [],
      echoFlag: true,
    };
  },
  watch: {
    FileList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val?.length > 0) {
          if (this.echoFlag) {
            this.echoFlag = false;
          }
          this.i = val.length + 1;
          const name = val[val.length - 1].name;
          const indexArr = name.match(/\d+/g);
          let index = indexArr.join();
          this.i = index--;
        } else {
          this.i = 0;
        }
      },
    },
  },
  computed: {
    FileList: {
      get() {
        return this.fileList;
      },
      set(val) {
        this.$emit("update:fileList", [...this.fileList, val]);
      },
    },
  },
  methods: {
    format(percentage) {
      return percentage === 100 ? "满" : `${percentage}%`;
    },
    async uploadFile(fileObj) {
      const flag = Boolean(JSON.parse(sessionStorage.getItem("uploadFlag")));
      this.flag = flag;
      if (flag) {
        const file = fileObj.file;
        const formData = new FormData();
        formData.set("file", file);
        const res = await request({
          url: `/share/oss/fileUpload`,
          method: "post",
          data: formData,
        });

        if (res.status == 0) {
          this.index++;
          this.FileList.forEach((item) => {
            if (item.uid == file.uid) {
              item.url = res.data;
            }
          });
          this.$emit("validateHandle");
        }
      } else {
        this.$refs.upload.uploadFiles.pop();
      }
      if (sessionStorage.getItem("uploadFlag")) {
        sessionStorage.setItem("uploadFlag", false);
      }
    },

    onPreview(name) {
      this.imgList = [];
      const file = this.FileList.find((item) => item.name == name);
      const index = file.url.indexOf(".pdf");
      if (index > -1) {
        let suffix = "";
        let url = "";
        let name = "";
        if (file.url) {
          url = file.url;
          name = file.name;
          suffix = url.substring(url.lastIndexOf("."), url.length);
        } else {
          url = file.url;
          name = file.name;
          suffix = ""; // name里面已经包含了后缀
        }

        // 下载
        const downloadElement = document.createElement("a");
        downloadElement.href = url;
        downloadElement.target = "_blank";
        downloadElement.download = `${name}${suffix}`; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
      } else {
        this.imgList = [file.url];
        this.imgViewerVisible = true;
      }
    },

    onClose(name) {
      const INDEX = this.FileList.findIndex((item) => item.name == name);

      if (INDEX > -1) {
        this.FileList.splice(INDEX, 1);
      }
      if (this.$listeners.validateHandle) {
        this.$emit("validateHandle");
      }
    },

    onSuccess(response, file) {
      const suffix = file.url.slice(file.url.lastIndexOf("."));
      const flag = suffix == ".pdf";
      if (!flag) {
        this.FileList.forEach((item) => {
          if (item.name == file.name) {
            setTimeout(() => {
              item.percentage = Math.floor(Math.random() * 25 + 15);
            }, 140);
            setTimeout(() => {
              item.percentage = Math.floor(Math.random() * 50 + 15);
            }, 270);
            setTimeout(() => {
              item.percentage = 100;
              setTimeout(() => {
                item.REFS = false;
              }, 500);
            }, 400);
          }
        });
      } else {
        this.FileList.forEach((item) => {
          if (item.name == file.name) {
            setTimeout(() => {
              item.percentage = Math.floor(Math.random() * 0 + 15);
            }, 70);
            setTimeout(() => {
              item.percentage = Math.floor(Math.random() * 30 + 15);
            }, 160);
            setTimeout(() => {
              item.percentage = Math.floor(Math.random() * 50 + 15);
            }, 300);
            setTimeout(() => {
              item.percentage = Math.floor(Math.random() * 80 + 15);
            }, 450);
            setTimeout(() => {
              item.percentage = 100;
              setTimeout(() => {
                item.REFS = false;
              }, 800);
            }, 600);
          }
        });
      }
    },
    onError(err, file, fileList) {
      const index = this.FileList.findIndex((item) => item.name == file.name);
      if (index > -1) {
        this.FileList.splice(index, 1);
      }
      if (this.$listeners.onError) {
        return this.$emit("onError", err, file, fileList);
      }
    },
    beforeUpload(file) {
      if (this.$listeners.beforeUpload) {
        this.$emit("beforeUpload", file);
        const flag = Boolean(JSON.parse(sessionStorage.getItem("uploadFlag")));
        if (flag) {
          this.i++;
          const index = this.i;
          this.FileList.push({
            name: this.name + this.i,
            uid: file.uid,
            url: null,
            percentage: 0,
            REFS: "percentage" + index,
          });
        }
      }
    },
    onExceed() {
      this.$message.error("上传文件的个数有限");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  text-align: left;
}

::v-deep .upload-demo .el-upload-list__item.is-success:focus:not(:hover) {
  display: none !important;
}

.fileTemp {
  width: 90%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  box-sizing: border-box;

  span {
    height: 20px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
  }

  & > div {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1;

    width: 100%;
    justify-content: space-between;
    position: relative;

    span:first-of-type {
      flex: 1;
    }

    .el-icon-close {
      position: absolute;
      top: 8px;
      right: 0px;
      display: none;
      color: #999999;
    }

    &:hover {
      height: 20px;
      color: #409eff;
      background-color: rgba(236, 234, 234, 0.25);
      cursor: pointer;

      .el-icon-circle-check {
        display: none;
      }

      .el-icon-close {
        display: block;
      }
    }

    .el-icon-upload-success {
      color: #67c23a;
    }
  }
}
</style>
