<template>
  <el-input
    class="inpt"
    v-if="modelType == 'default'"
    v-model="VALUE"
    :maxlength="maxlength"
    :type="type"
    v-on="$listeners"
    v-bind="$attrs"
    :placeholder="placeholder"
    :style="styles"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </el-input>
  <el-input
    v-else-if="modelType == 'number'"
    v-model.number="VALUE"
    v-on="$listeners"
    v-bind="$attrs"
    :type="type"
    :placeholder="placeholder"
    :style="styles"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </el-input>
  <el-input
    v-else-if="modelType == 'trim'"
    v-model.trim="VALUE"
    :type="type"
    v-on="$listeners"
    v-bind="$attrs"
    :placeholder="placeholder"
    :style="styles"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </el-input>
  <el-input
    v-else-if="modelType == 'lazy'"
    v-model.lazy="VALUE"
    :maxlength="maxlength"
    :type="type"
    v-on="$listeners"
    v-bind="$attrs"
    :placeholder="placeholder"
    :style="styles"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script>
export default {
  name: "inputView",
  props: {
    modelType: {
      type: String,
      default: () => "default",
    },
    type: { default: () => "text", type: String },
    value: { require: true },
    placeholder: { type: String, default: "请选择" },
    styles: { type: String, default: () => "width:217px;" },
    maxlength: { default: () => 20 },
  },
  computed: {
    VALUE: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -o-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}
</style>
