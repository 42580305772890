<template>
  <div>
    <el-upload :file-list="fileList" :action="uploadUrl" :on-success="onSuccess" :on-error="onError"
      :on-remove="onRemove" :before-upload="beforeUpload" :on-preview="onPreview" :headers="headers" :limit="limits"
      :on-exceed="onExceed" list-type="text">
      <slot>
        <el-button size="small" type="primary">
          点击上传
        </el-button>
      </slot>
    </el-upload>
  </div>
</template>

<script>
import { getTokenCookie } from '@/utils/cookies'
import Compressor from 'compressorjs'

export default {
  name: 'UploadImage',
  inheritAttrs: false,
  props: {
    value: {
      default: () => []
    },
    title: {
      type: String
    },
    // 文件个数
    limits: {
      type: Number,
      default: 5
    },
    // 文件大小
    limitSize: {
      type: Number,
      default: 1
    },
    // 文件格式
    uploadType: {
      default: () => ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'xlsx', 'zip']
    },
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + '/share/oss/fileUpload',
      headers: { Authorization: getTokenCookie() },
      fileList: []
    }
  },
  created() {
    const files = JSON.parse(JSON.stringify(this.value))
    if (files && files.length > 0) {
      this.fileList = files.map((item, index) => {
        item.name = (item.title || this.title) + (index + 1)
        return item
      })
    }
  },
  methods: {
    async onError(err) {
      this.$message({
        message: err.message,
        type: 'error',
        duration: 3 * 1000
      })
    },

    async onSuccess(response) {
      if (response.status) {
        this.$message.error(response.message)
        return
      }
      const files = this.value || []
      const url = response.data
      console.log(files)
      files.push({ url })
      this.$emit('input', files)
    },

    async onRemove(file) {
      const files = this.value.filter(x => x.url !== (file.url || file.response.data))
      this.$emit('input', files)
    },
    async onPreview(file) {
      let suffix = ''
      let url = ''
      let name = ''
      if (file.url) {
        url = file.url
        name = file.name
        suffix = url.substring(url.lastIndexOf('.'), url.length)
      } else {
        url = file.url
        name = file.name
        suffix = '' // name里面已经包含了后缀
      }

      // 下载
      const downloadElement = document.createElement('a')
      downloadElement.href = url
      downloadElement.target = '_blank'
      downloadElement.download = `${name}${suffix}` // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
    },
    onExceed() {
      this.$message.error('超出文件数量限制') /**/
    },
    // 判断是否超过限制
    limit(file) {
      let isIMAGE = true
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = this.uploadType
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件格式不正确');
        isIMAGE = false;
      }
      const isLimit = file.size / 1024 / 1024 < this.limitSize
      if (!isLimit) {
        this.$message.error(`上传大小不能超过${this.limitSize}MB`)
      }
      return isLimit && isIMAGE
    },
    beforeUpload(file) {
      const compressorTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
      // 压缩图片
      if (compressorTypes.includes(file.type) && this.isCompress) {
        return new Promise((resolve, reject) => {
          /* eslint-disable no-new */
          new Compressor(file, {
            quality: 0.8,
            maxWidth: 1080,
            maxHeight: 1920,
            success: (result) => {
              file = new File([result], file.name, {
                type: file.type,
                lastModified: Date.now()
              })
              if (!this.limit(file)) {
                reject(new Error(false))
              }
              resolve(file)
            }
          })
        })
      }
      return this.limit(file)
    }
  }
}
</script>

<style scoped>

</style>
