<template>
  <el-select v-model="VALUE" :style="styles" v-on="$listeners" v-bind="$attrs">
    <div
      v-for="(item, index) in options"
      :key="item.label + index + item.value"
    >
      <el-option
        v-if="item.has"
        v-has="item.hasName"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
      <el-option v-else :label="item.label" :value="item.value"> </el-option>
    </div>
  </el-select>
</template>

<script>
export default {
  name: "selectView",
  data() {
    return {};
  },
  props: {
    value: { default: () => "", required: true },
    options: { type: Array, require: true },
    styles: { type: String, default: () => "width:217px" },
  },

  computed: {
    VALUE: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
