import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/free.css";
import "@/router/routerWards.js";

// 引入一些配置
import MyPlugin from "@/plugins/index.js";

Vue.config.productionTip = false;
Vue.use(MyPlugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
