<template>
  <div class="wrapper" v-if="obj.list.length > 0">
    <div class="picture" v-if="obj.title">{{ obj.title && obj.title }}</div>
    <div class="flex flex-wrap px-2 py-1" style="grid-gap: 10px 10px">
      <div v-for="(item, index) in obj.list" :key="index">
        <div
          class="item"
          @click="previewHandle(item.imgList)"
          v-if="item.num > 0"
        >
          <div class="shell">
            <el-image
              :class="item.imgList.length > 0 ? 'have' : 'not-have'"
              :src="item.imgList.length == 0 ? shadowUrl : item.imgList[0]"
              fit="fit"
            ></el-image>
          </div>
          <div>
            {{ item.name }}<span>(共{{ item.num }}张)</span>
          </div>
        </div>
      </div>
    </div>
    <el-image-viewer
      style="z-index:9999; !important"
      v-if="imgViewerVisible"
      :on-close="
        () => {
          imgViewerVisible = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  name: "imgPreview",
  props: {
    obj: {},
  },
  data() {
    return {
      shadowUrl: require("@/assets/liftingEquipment/shadow.png"),
      imgViewerVisible: false,
      imgList: [],
    };
  },
  methods: {
    previewHandle(imgList) {
      console.log(imgList);
      if (imgList instanceof Array) {
        this.imgList = imgList;
      } else {
        this.imgList = [];
      }
      this.imgViewerVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-image-viewer__wrapper {
  position: absolute;
}

.not-have {
  width: 60px;
  height: 50px;
}

.have {
  width: 100%;
  height: 100%;
}

::v-deep .el-image-viewer__canvas {
  position: absolute;
  height: 80%;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.item:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition-duration: 0.1s;
}

.picture {
  width: 100%;
  padding: 15px 0px;
  border-bottom: 1px solid #e4e4e4;
  color: #70667a;
  font-size: 16px;
  padding-left: 18px;
  font-weight: 700;
}

.item {
  width: 150px;
  height: 170px;
  border: 1px solid #e4e4e4;

  &:nth-child(odd) {
    background-color: #f9fafc;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;

  .shell {
    width: 100px;
    height: 100px;
    background-color: #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > div:last-of-type {
    span {
      display: inline-block;
      margin-left: 10px;
      color: #02a7f0;
    }
  }
}
</style>
