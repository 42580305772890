import request from '@/utils/request'

export const getUserListByCellphone = (cellphone) => {
  return request({
    url: `/user/getUserListByCellphone/${cellphone}`,
    method: 'get'
  })
}
/**
 * 获取项目名称弹框
 * @param mobile
 * @returns {AxiosPromise}
 */
export const getProjectSpringFrame = (data) => {
  return request({
    url: '/realname/select/getProjectSpringFrame',
    method: 'post',
    data
  })
}

/**
 * 获取项目班组名称
 * @param mobile
 */
export const getTeamList = (params) => {
  return request({
    url: `/realname/team/list`,
    method: 'get',
    params
  })
}