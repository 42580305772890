<template>
  <el-container class='my-container'>
    <el-aside :width="iscollapse ? '60px' : '240px'">
      <SlideBar />
    </el-aside>
    <el-container>
      <el-header>
        <HeadBar />
      </el-header>
      <el-main>
        <AppMain />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SlideBar from '@/layout/components/slideBar'
import HeadBar from '@/layout/components/headBar'
import AppMain from '@/layout/components/appMain'
export default {
  name: 'FrameIndex',
  components: {
    SlideBar,
    HeadBar,
    AppMain
  },
  computed: {
    iscollapse() {
      return this.$store.state.cookies.sidebar_status
    },
  },
}
</script>

<style scoped>
.my-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.el-header {
  padding: 0;
  height: 50px !important;
}

.el-main {
  height: calc(100vh - 50px);
  flex: 1;
  padding: 0;
  overflow-y: auto;
  display: flex;
}

.el-aside {
  background-color: #131a2c;
  transition: 0.5s;
  scrollbar-width: none;
}
</style>
