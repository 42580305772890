import request from '@/utils/request'
// 菜单相关的接口
// params
// 我的菜单
export const getMyMenus = () =>
  request({
    url: '/user/getMyMenuList',
    method: 'get'
  })

/**
* 根据顶级菜单编码获取树形菜单+按钮
* @param params
* @returns {AxiosPromise}
*/
export const getMyTreeList = () =>
  request({
    url: '/business/menu/getMyMenuList/business',
    method: 'get'
  })
