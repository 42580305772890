import request from "@/utils/request";
import { exportFile } from "@/utils/exportFile";
//设备运行-统计接口
const type = "/iotlift";
export const getRunningStatistics = (params) => {
  return request({
    url: `${type}/device/running/statistics`,
    method: "get",
    params,
  });
};
//设备运行 - 列表接口
export const getRunningList = (params) => {
  return request({
    url: `${type}/device/running/list`,
    method: "get",
    params,
  });
};

//导出
export const runningExport = (data) => {
  exportFile(`${type}/device/running/export`, "塔机工作数据列表", data);
};

//安拆登记
export const getRunningNodes = (params) => {
  return request({
    url: `${type}/use/nodes/${params}`,
    method: "get",
  });
};

//安拆登记   安装告知
export const getInstallDetail = (params) => {
  return request({
    url: `${type}/use/install/detail/${params}`,
    method: "get",
  });
};

//安拆登记  安装告知 - 编辑
export const editInstall = (data) => {
  return request({
    url: `${type}/use/install/update`,
    method: "post",
    data,
  });
};

//服务单位  详情
export const getBusinessDetail = (params) => {
  return request({
    url: `${type}/unit/device/list`,
    method: "get",
    params,
  });
};

//安拆登记  服务单位 - 新增
export const insertSave = (data) => {
  return request({
    url: `${type}/unit/save`,
    method: "post",
    data,
  });
};
//  /unit/delete

//安拆登记  服务单位 - 新增
export const deleteSave = (data) => {
  return request({
    url: `${type}/unit/delete`,
    method: "post",
    data,
  });
};

//安拆登记 使用登记
export const getRegisterDetail = (params) => {
  return request({
    url: `${type}/use/register/detail/${params}`,
    method: "get",
  });
};

//安拆登记  使用登记 - 编辑
export const editRegister = (data) => {
  return request({
    url: `${type}/use/register/update`,
    method: "post",
    data,
  });
};

//安拆登记  顶升加节
export const getJointDetail = (params) => {
  return request({
    url: `${type}/use/joint/detail/${params}`,
    method: "get",
  });
};

//安拆登记  顶升加节  编辑
export const editJoint = (data) => {
  return request({
    url: `${type}/use/joint/update`,
    method: "post",
    data,
  });
};

//安拆登记  拆卸告知
export const getRemoveDetail = (params) => {
  return request({
    url: `${type}/use/remove/detail/${params}`,
    method: "get",
  });
};

//安拆登记  拆卸告知  编辑
export const editRemove = (data) => {
  return request({
    url: `${type}/use/remove/update`,
    method: "post",
    data,
  });
};

//安拆登记  安装告知  申请
export const installSave = (data) => {
  return request({
    url: `${type}/use/install/save`,
    method: "post",
    data,
  });
};

//安拆登记  使用登记  申请
export const registerSave = (data) => {
  return request({
    url: `${type}/use/register/save`,
    method: "post",
    data,
  });
};

//安拆登记  顶升加节  申请
export const jointSave = (data) => {
  return request({
    url: `${type}/use/joint/save`,
    method: "post",
    data,
  });
};

//安拆登记  拆卸告知  申请
export const removeSave = (data) => {
  return request({
    url: `${type}/use/remove/save`,
    method: "post",
    data,
  });
};

//设备人员管理 详情
export const getPersonList = (params) => {
  return request({
    url: `${type}/person/device/list`,
    method: "get",
    params,
  });
};

//设备人员管理  添加
export const personSave = (data) => {
  return request({
    url: `${type}/person/save`,
    method: "post",
    data,
  });
};

//设备人员管理  删除
export const personDelete = (data) => {
  return request({
    url: `${type}/person/delete`,
    method: "post",
    data,
  });
};
//下发整改  申请
export const problemSave = (data) => {
  return request({
    url: `${type}/problem/save`,
    method: "post",
    data,
  });
};

//使用注销  申请
export const cancelSave = (data) => {
  return request({
    url: `${type}/use/cancel/save`,
    method: "post",
    data,
  });
};

//保险  全部
export const getlnsureList = (params) => {
  return request({
    url: `${type}/insure/list`,
    method: "get",
    params,
  });
};

//保险  新增
export const insureSave = (data) => {
  return request({
    url: `${type}/insure/save`,
    method: "post",
    data,
  });
};

//保险  详情
export const insureInfo = (id) => {
  return request({
    url: `${type}/insure/info/${id}`,
    method: "post",
  });
};

//保险  修改
export const insureUpdate = (data) => {
  return request({
    url: `${type}/insure/update`,
    method: "post",
    data,
  });
};
//保险  删除
export const insureDelete = (data) => {
  return request({
    url: `${type}/insure/delete`,
    method: "post",
    data,
  });
};

//备忘录  全部
export const memorandumList = (params) => {
  return request({
    url: `${type}/memorandum/list`,
    method: "get",
    params,
  });
};

//备忘录  新增
export const memorandumSave = (data) => {
  return request({
    url: `${type}/memorandum/save`,
    method: "post",
    data,
  });
};

//备忘录  新增
export const memorandumUpdate = (data) => {
  return request({
    url: `${type}/memorandum/update`,
    method: "post",
    data,
  });
};

//备忘录  删除
export const memorandumDelete = (data) => {
  return request({
    url: `${type}/memorandum/delete`,
    method: "post",
    data,
  });
};
//

//产权设备库  删除
export const deviceDelete = (data) => {
  return request({
    url: `${type}/device/delete`,
    method: "post",
    data,
  });
};

//运行设备库  移除设备
export const deviceRemove = (data) => {
  return request({
    url: `${type}/device/remove`,
    method: "post",
    data,
  });
};

//
//阈值  升降机
export const hoistThreshold = (id) => {
  return request({
    url: `${type}/hoist/threshold/${id}`,
    method: "get",
  });
};
//阈值  起重机
export const craneThreshold = (id) => {
  return request({
    url: `${type}/crane/threshold/${id}`,
    method: "get",
  });
};

/**
 *作业中心-初次安装--详情
 */
export const installDetail = (taskId) => {
  return request({
    url: `/iotlift/task/install/detail/${taskId}`,
    method: "get",
  });
};

/**
 * 政务中心-使用注销-详情
 */
export const cancelDetail = (logId) => {
  return request({
    url: `/iotlift/use/cancel/detail/${logId}`,
    method: "get",
  });
};

/**
 * 政务中心-使用登记-详情
 */
export const registerDetail = (logId) => {
  return request({
    url: `/iotlift/use/register/detail/${logId}`,
    method: "get",
  });
};

/**
 * 政务中心-拆卸告知-详情
 */
export const removeDetail = (logId) => {
  return request({
    url: `/iotlift/use/remove/detail/${logId}`,
    method: "get",
  });
};

/**
 * 政务中心-顶升告知-详情
 */
export const jointDetail = (logId) => {
  return request({
    url: `/iotlift/use/joint/detail/${logId}`,
    method: "get",
  });
};

/**
 * 政务中心-设备信息操作记录
 */
export const logList = (params) => {
  return request({
    url: "/iotlift/center/log/list",
    method: "get",
    params,
  });
};
//工作统计  起重机
export const craneWork = (id) => {
  return request({
    url: `${type}/crane/work/${id}`,
    method: "get",
  });
};

//工作统计  升降机
export const hoistWork = (id) => {
  return request({
    url: `${type}/hoist/work/${id}`,
    method: "get",
  });
};
