import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Loading, Message, MessageBox } from 'element-ui'
import { getTokenCookie, removeTokenCookie } from '@/utils/cookies'
import router from '@/router'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import configs from '@/config'
import { ElLoadingComponent } from 'element-ui/types/loading'
/**
 * axios封装
 * @param baseURL 请求前缀(根路径)
 * @param timeout 超时时间
 * @param loadingTime 显示loading时间
 */
const createService = ({ baseURL = '/api', timeout = 1000 * 10, loadingTime = 1000 * 2, loading = true, headers = null, headersData = null }) => {
  console.log(loading, '调用了')
  const service = axios.create({
    baseURL,
    timeout
  })

  let loadingInstance: ElLoadingComponent | any = null
  let timer: number | null = null
  service.interceptors.request.use(
    (config: AxiosRequestConfig | any) => {
      // 暂时隐藏加载效果，但有遮罩层，超过两秒显示加载效果
      if (loading) {
        loadingInstance = Loading.service({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          customClass: 'create-isLoading',
          background: 'rgba(0, 0, 0, 0)'
        })
        timer = setTimeout(() => {
          loadingInstance.customClass = ''
        }, loadingTime)
      }

      // config.headers.version = configs.env.VERSION
      const token = getTokenCookie()
      if (token) {
        config.headers.Authorization = token
      }
      if (headers !== null) {
        config.headers[headers] = headersData
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  // Response interceptors
  service.interceptors.response.use(
    (response: AxiosResponse) => {
      loadingInstance?.close()
      if (timer) {
        clearTimeout(timer)
      }
      const res = response.data
      if (response.status === 200) {
        if (res.status !== 0) {
          if (res.status === 403) {
            MessageBox.confirm('当前登录状态已过期，请重新登录', '错误', {
              confirmButtonText: '重新登录',
              // cancelButtonText: 'Cancel',
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              type: 'warning'
            }).then(() => {
              const { UserModule } = require('@/store/modules/user')
              UserModule.Logout()
              removeTokenCookie();

              router.push('/login')
            })
          }
          Message.error(res.msg || res.message)
          return Promise.reject(res)
        }
        return res
      } else {
        console.warn('response:' + response + ' -> ' + JSON.stringify(response))
        // 为了兼容1.0的代码
        const data = response.data
        Message.error(data.msg || data.message)
        return Promise.reject(response.data)
      }
    },
    (error: AxiosError<any>) => {
      loadingInstance?.close()
      if (timer) {
        clearTimeout(timer)
      }
      // 其他错误
      if (!error.response || !error.response.data) {
        Message.error('网络错误')
        return Promise.reject(error)
      }
      // 处理401问题
      if (error.response.status === 401) {
        MessageBox.confirm('当前登录状态已过期，请重新登录', '错误', {
          confirmButtonText: '重新登录',
          // cancelButtonText: 'Cancel',
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          const { UserModule } = require('@/store/modules/user')
          UserModule.Logout()
          removeTokenCookie();

          router.push('/login')
        })
      }
      const data = error.response.data
      Message.error(data.msg || data.message)
      return Promise.reject(error)
    }
  )

  return service
}

export default createService
