<template>
  <el-menu :default-active="$route.path" router background-color="#131a2c" :unique-opened="true" :collapse="iscollapse"
    :collapse-transition="false" mode="vertical" text-color="#fff" active-text-color="#257bcc" class="menu">
    <SidebarItem :data="menu.menuList"></SidebarItem>
  </el-menu>
</template>

<script>
import SidebarItem from '@/layout/components/sunComponents/SidebarItem';
export default {
  components: {
    SidebarItem
  },
  name: 'slideBar',
  computed: {
    menu() {
      const menu = this.$store.getters['optionsMenu/getMenuinfo']
      return menu;
    },
    iscollapse() {
      return this.$store.state.cookies.sidebar_status
    },
  },
  methods: {
    skip() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.el-image {
  width: 14px;
  height: 14px;
}

span {
  margin-left: 14px;
}

.el-menu-item:hover,
:deep(.el-submenu__title:hover) {
  background-color: #212C3B !important;
}

.el-menu {
  border-right: solid 0px #e6e6e6;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #FFF;
}
</style>
