import request from '@/utils/request'
// 字典通用的
/**
 * 获取字典
 * @param mobile
 * @returns {AxiosPromise}
 */
export const getOptions = () => {
  return request({
    url: '/share/dict/getAllDictCodeList',
    method: 'get'
  })
}
