<template>
  <dialogView
    :title="dialogObj?.title || ''"
    :width="dialogObj?.width || ''"
    :height="dialogObj?.height || 'auto'"
    :visible.sync="DialogVisible"
  >
    <div
      class="wrapper"
      :style="{ height: dialogObj?.height ? dialogObj?.height : '80vh' }"
    >
      <div class="insert-box">
        <el-form
          :model="MODEL"
          :rules="rules"
          ref="form"
          :label-width="dialogObj?.labelWidth || '200px'"
          class="mt-2 mb-1"
          :style="!dialogObj.disabled ? 'padding-bottom: 10px;' : ''"
          :key="DialogVisible"
        >
          <div v-for="(item, index) in ruleFormArray" :key="index">
            <!-- 下拉框 -->
            <el-form-item
              :label="item.label"
              :prop="item.prop"
              v-if="item?.type == 'select'"
              class=""
            >
              <div class="flex">
                <selectView
                  v-if="item.flag ? item.flag : true"
                  :clearable="true"
                  :filterable="item.filterable"
                  :options="item.options"
                  :disabled="item?.disabled"
                  :value.sync="MODEL[item.name]"
                  :placeholder="item?.placeholder"
                  :styles="item.styles ? item.styles : 'width:180px'"
                >
                </selectView>
                <enterpriseSelect
                  v-else
                  :form="item.form"
                  :prop="item.prop"
                  v-model="MODEL[item.name]"
                  :disabled="item?.disabled"
                  :placeholder="item?.placeholder"
                >
                </enterpriseSelect>
                <el-button
                  style="margin-left: 10px"
                  v-if="item?.btns"
                  @click="item.btnHandle"
                  >添加单位</el-button
                >
              </div>
            </el-form-item>
            <!-- 文本框 -->
            <el-form-item
              :label="item.label"
              :prop="item.prop"
              v-if="item?.type == 'input'"
            >
              <inputView
                v-if="!item.formatType"
                :value.sync="MODEL[item.name]"
                :placeholder="item.placeholder ? item.placeholder : ''"
                :maxlength="item?.maxlength"
                :class="item?.className"
                :clearable="true"
                :disabled="item?.disabled"
                :rows="item?.rows"
                :type="item?.TYPE || 'text'"
                :styles="item?.styles || 'width:180px'"
              >
              </inputView>
              <inputView
                v-if="item.formatType == 1"
                :value.sync="MODEL[item.name]"
                :placeholder="item.placeholder ? item.placeholder : ''"
                :maxlength="item?.maxlength"
                :class="item?.className"
                :clearable="true"
                :disabled="item?.disabled"
                :rows="item?.rows"
                :type="item?.TYPE || 'text'"
                :styles="item?.styles || 'width:180px'"
                v-LimitNumber
                @input="item.inputChange(MODEL, `${item.name}`)"
              >
              </inputView>
              <inputView
                v-if="item.formatType == 2"
                :value.sync="MODEL[item.name]"
                :placeholder="item.placeholder ? item.placeholder : ''"
                :maxlength="item?.maxlength"
                :class="item?.className"
                :clearable="true"
                :disabled="item?.disabled"
                :rows="item?.rows"
                :type="item?.TYPE || 'text'"
                :styles="item?.styles || 'width:180px'"
                v-LimitNumber
                @input="item.inputChange(MODEL, `${item.name}`)"
                @blur="item.blurHandle($event, `${item.name}`)"
              >
              </inputView>
              <inputView
                v-if="item.formatType == 3"
                :value.sync="MODEL[item.name]"
                :maxlength="item?.maxlength"
                :placeholder="item.placeholder ? item.placeholder : ''"
                :rows="item?.rows"
                :class="item?.className"
                :clearable="true"
                :disabled="item?.disabled"
                :type="item?.TYPE || 'text'"
                :styles="item?.styles || 'width:180px'"
                @input="item.inputChange(MODEL, `${item.name}`)"
              >
              </inputView>
              <select-project-form
                v-model="MODEL[item.name]"
                placeholder="请选择"
                v-if="item.formatType == 4"
                :style="item?.styles || 'width:90%'"
              />
            </el-form-item>
            <!-- 时间控件 -->
            <el-form-item
              :label="item.label"
              :prop="item.prop"
              v-if="item?.type == 'date'"
            >
              <el-date-picker
                v-model="MODEL[item.name]"
                type="date"
                :placeholder="item?.placeholder"
                :disabled="item?.disabled"
                :style="item?.styles || 'width:180px'"
                :value-format="
                  item.valueFormat ? item.valueFormat : 'yyyy-MM-dd'
                "
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              :label="item.label"
              :prop="item.prop"
              v-if="item?.type == 'daterange'"
            >
              <el-date-picker
                v-model="MODEL[item.name]"
                type="daterange"
                range-separator="至"
                :disabled="item?.disabled"
                :placeholder="item?.placeholder"
                :style="item?.styles || 'width:180px'"
                :value-format="
                  item.valueFormat ? item.valueFormat : 'yyyy-MM-dd'
                "
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item
              :label="item.label"
              :prop="item.prop"
              v-if="item?.type == 'radio'"
            >
              <el-radio-group v-model="MODEL[item.name]">
                <el-radio
                  :disabled="item?.disabled"
                  :label="0"
                  @input="item?.validateHandle(item.name, $refs.form)"
                  >未提交</el-radio
                >
                <el-radio
                  :disabled="item?.disabled"
                  :label="1"
                  @input="item?.validateHandle(item.name, $refs.form)"
                  >已提交</el-radio
                >
              </el-radio-group>
            </el-form-item>

            <!-- 文件上传 -->
            <el-form-item
              :label="item.label"
              :prop="item.prop"
              v-if="item?.type == 'upload'"
            >
              <upload-file-array
                v-model="MODEL[item.name]"
                :title="item?.uploadName"
                :uploadType="item?.uploadType"
                :accept="item?.accept"
                :limitSize="item?.limitSize"
              >
                <div slot="con-button" class="slot-style">
                  {{ item.text }}
                </div>
              </upload-file-array>
              <!-- <uploadView
                @beforeUpload="item._beforeUpload"
                :style="item?.styles || 'width:180px'"
                :name="item?.uploadName"
                :fileList.sync="MODEL[item.name]"
                :multiple="false"
                :limit="item?.limit"
                :listType="item?.listType || 'text'"
                :accept="item?.accept"
                @validateHandle="item?.validateHandle(item.name, $refs.form)"
              >
                <el-button
                  :type="item?.btnType || ''"
                  :disabled="item?.disabled"
                  size="small"
                  plain
                >
                  <div class="flex align-center" style="display: inline-block">
                    <el-image
                      v-if="item.imgFlag"
                      style="width: 12px; height: 12px"
                      :src="uploadSrc"
                      fit="fit"
                      class="mr-1"
                    ></el-image>
                    {{ item.uploadText ? item.uploadText : "上传" }}
                  </div>
                </el-button>
                <div slot="tip" class="el-upload__tip">
                  {{ item.text }}
                </div>
              </uploadView> -->
            </el-form-item>
          </div>
        </el-form>
        <div
          class="btn-box"
          v-if="dialogObj.disabled ? !dialogObj.disabled : true"
        >
          <div>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="submitForm('form')"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </dialogView>
</template>

<script>
import _ from "lodash";
export default {
  name: "formVerify",
  props: {
    dialogVisible: { type: Boolean, default: () => false },
    ruleForm: { require: true },
    model: {
      type: Object,
    },
    rules: {},
    ruleFormArray: {},
    dialogObj: {},
  },

  data() {
    return {
      action: process.env.VUE_APP_BASE_API + "/share/oss/fileUpload",
      uploadSrc: require("@/assets/liftingEquipment/upload.png"),
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      MODEL: {},
    };
  },
  watch: {
    model: {
      deep: true,
      immediate: true,
      handler() {
        const obj = _.cloneDeep(this.model);
        for (const key in obj) {
          this.$set(this.MODEL, key, obj[key]);
        }
      },
    },
    "MODEL.requestFiles"() {
      //产权备案资料PDF ||使用注销登记表
      this.$refs.form && this.validateHandle("requestFiles", this.$refs.form);
    },
    "MODEL.recordFiles"() {
      //备案证
      this.$refs.form && this.validateHandle("recordFiles", this.$refs.form);
    },
    "MODEL.liveFiles"() {
      //备案现场核查表
      this.$refs.form && this.validateHandle("liveFiles", this.$refs.form);
    },
    "MODEL.changeFiles"() {
      //产权变更申请表
      this.$refs.form && this.validateHandle("changeFiles", this.$refs.form);
    },
    "MODEL.cancelFiles"() {
      //产权注销资料PDF
      this.$refs.form && this.validateHandle("cancelFiles", this.$refs.form);
    }, //
    "MODEL.imageList"() {
      //隐患图片
      this.$refs.form && this.validateHandle("imageList", this.$refs.form);
    },
    "MODEL.registerFiles"() {
      //使用登记证
      this.$refs.form && this.validateHandle("registerFiles", this.$refs.form);
    },
  },
  computed: {
    DialogVisible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.MODEL = {};
        this.$emit("update:dialogVisible", val);
      },
    },
    // MODEL() {

    //     return this.model;
    // },
  },
  methods: {
    validateHandle(name, el) {
      el.validateField(name);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const obj = _.cloneDeep(this.MODEL);
          this.$emit("submit", obj);
          this.DialogVisible = false;
        } else {
          return false;
        }
      });
    },
    onClose() {
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
      this.DialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0px 18px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
    margin: 0px 2px 0px 7px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding-top: 10px;
    margin-right: 8px;
    border-top: 1px solid #e4e4e4;
  }
}
.wrapper {
  height: 80vh;
}

.insert-box {
  width: 100%;
  height: 98%;
  overflow: hidden;
  overflow-y: auto;
  margin: 0 auto;
  border: 1px solid #e4e4e4;
  background-color: #f5f9f8;
  opacity: 1;

  ::v-deep .el-input__inner {
    // border: 1px solid #e4e4e4 !important;
  }
}
.ruleForm {
  background-color: #f5f9f8;
  height: 100%;
  width: 100%;
  padding-top: 10px;
}

::v-deep .el-textarea__inner {
  //el_input中的隐藏属性
  resize: none; //禁止拉伸
}

.enterprise-title {
  color: #303133;
  text-align: center;
  background-color: #f9f9f9;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.btn {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 0px;
  margin-left: 0.01vw;
  background-color: #fff;

  ::v-deep .el-button {
    margin-left: 10px !important;
  }
}

// .btn {
//     width: 100%;
//     // position: fixed;
//     // bottom: 0px;
//     // left: 50%;
//     // transform: translateX(-50%);
//     border-top: 1px solid #e4e4e4;
//     padding: 10px 0px;
//     background-color: #fff;

//     ::v-deep .el-button {
//         margin-left: 10px !important;
//     }
// }
</style>
