import ProjectUnitTeam from '@/components/Select/project-unit-team.vue' // 选择项目单位班组
import SelectAddress from '@/components/Select/select-address.vue' // 选择省市区
// import SelectEnterprisesForm from '@/components/Select/select-enterprises-form.vue' // 选择企业
import SelectEnterprises from '@/components/Select/select-enterprise.vue' // 企业下拉框
import SelectProjectForm from '@/components/Select/select-project-spring-frame.vue' // 选择项目
import SelectClassify from '@/components/Select/select-classify.vue' // 选择保险分类
import SelectPersonalForm from '@/components/Select/select-personal-form.vue' // 选择人员

import UploadFile from '@/components/upload/upload-file.vue' // 图片上传单个文件（字符串）
import UploadFileArray from '@/components/upload/upload-file-array.vue' // 图片上传多个文件（数组对象）
import UploadFileArrayObject from '@/components/upload/upload-file-array-object.vue' // 图片上传多个文件（数组对象）

import PasswordInput from '@/components/PasswordInput/index.vue'  //密码输入框
import selectView from "@/components/selectView/index.vue"
import inputView from "@/components/inputView/index.vue"
import dialogView from "@/components/dialogView/index.vue"
import formVerify from "@/components/formVerify/index.vue"
import tableView from "@/components/tableView/index.vue"
import paginationView from "@/components/paginationView/index.vue"
import columnView from "@/components/tableView/components/columnView.vue"
import imgPreview from "@/components/imgPreviewView/index.vue"
import ActionRecord from "@/components/ActionRecord/index.vue"
import DeviceInfo from "@/components/DeviceInfo/index.vue"
import fileListShowDownload from "@/components/fileListShowDownload/index.vue"
import uploadView from "@/components/uploadView/index.vue"
function plugins(Vue: any) {
  Vue.component('selectView', selectView);//下拉框
  Vue.component('inputView', inputView);//input框
  Vue.component('dialogView', dialogView);//弹窗
  Vue.component('formVerify', formVerify);//表单
  Vue.component('tableView', tableView);//表格
  Vue.component('paginationView', paginationView);//分页
  Vue.component('columnView', columnView);//表格列
  Vue.component('imgPreview', imgPreview);//图片预览
  Vue.component('ActionRecord', ActionRecord);
  Vue.component('DeviceInfo', DeviceInfo);
  Vue.component('fileListShowDownload', fileListShowDownload);
  Vue.component('uploadView', uploadView);

  Vue.component('select-classify', SelectClassify)
  Vue.component('project-unit-team', ProjectUnitTeam)
  Vue.component('select-project-form', SelectProjectForm)
  Vue.component('select-address', SelectAddress)
  // Vue.component('select-enterprises-form', SelectEnterprisesForm)
  Vue.component('select-enterprises', SelectEnterprises)
  Vue.component('select-personal-form', SelectPersonalForm)
  Vue.component('upload-file', UploadFile)
  Vue.component('upload-file-array', UploadFileArray)
  Vue.component('upload-file-array-object', UploadFileArrayObject)
  Vue.component('PasswordInput', PasswordInput)
}
export default plugins
