import axios from 'axios'
import { getTokenCookie } from './cookies'
import { formatQuery } from '@/utils/util'
import { Message } from 'element-ui'
/**
 * 下载文件
 * @param res 服务端响应，经过axios处理后的数据，详见src/utils/request.js部分的响应值配置
 */
const downloadFile = (res: any, title: any) => {
  const blob = new Blob([res.data])
  const fileName = getFileName(res.headers['content-disposition'])
  // 正常情况下文件名未返回即为导出异常,这里判断文件大小是为了避免后端忘记写文件名字返回,被视为异常

  if (!fileName && blob.size < 300) {
    const decoder = new TextDecoder('utf-8')
    const result = JSON.parse(decoder.decode(new Uint8Array(res.data)))
    Message.error(result.message)
    return
  }
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a')
    elink.download = title + '.xlsx' || fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    // navigator.msSaveBlob(blob, res.filename)
  }
}

function getFileName(contentDisposition: any) {
  if (!contentDisposition) {
    return
  }
  return contentDisposition.substring(contentDisposition.indexOf('='))
}
/**
 * GET下载文件
 * @param url
 * @param data
 * @returns {AxiosPromise}
 */
const download = (url: any, params: any) => {
  const token = getTokenCookie()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return axios({
    url,
    method: 'GET',
    responseType: 'arraybuffer',
    observe: 'response',
    params,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token
    },
    withCredentials: true
  })
}
/**
 * POST下载文件（常用于提交json数据下载文件）
 * @param url
 * @param data
 * @returns {AxiosPromise}
 */
const postDownload = (url: any, data: any) => {
  const token = getTokenCookie()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return axios({
    url,
    method: 'POST',
    responseType: 'arraybuffer',
    observe: 'response',
    data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: token
    },
    withCredentials: true
  })
}

// 文件导出(excel)
export const exportFile = (url: string, excelTitle: string, data: any, callback?: any): any => {
  postDownload(`${process.env.VUE_APP_BASE_API}${url}`, data)
    .then(function(response: any) {
      downloadFile(response, excelTitle)
    })
    .catch(() => {
      Message.error('未知错误')
    })
    .finally(() => {
      if (callback) {
        callback()
      }
    })
}
// 文件导出(excel)
export const getExportFile = (url: string, excelTitle: string, params: any, callback?: any): any => {
  download(`${process.env.VUE_APP_BASE_API}${url}`, formatQuery(params))
    .then(function(response: any) {
      downloadFile(response, excelTitle)
    })
    .catch(() => {
      Message.error('未知错误')
    })
    .finally(() => {
      if (callback) {
        callback()
      }
    })
}

// 文件导出(excel)
// export const exportIotFile = (url: string, excelTitle: string, data: any, callback?: any): any => {
//   postDownload(`${configs.env.VUE_APP_IOT_API}${url}`, data)
//     .then(function(response: any) {
//       downloadFile(response, excelTitle)
//     })
//     .catch((error: any) => {
//       Message.error('未知错误')
//     })
//     .finally(() => {
//       if (callback) {
//         callback()
//       }
//     })
// }

// // 文件导出(excel)
// export const getIotExportFile = (url: string, excelTitle: string, params: any, callback?: any): any => {
//   download(`${configs.env.VUE_APP_IOT_API}${url}`, formatQuery(params))
//     .then(function(response: any) {
//       downloadFile(response, excelTitle)
//     })
//     .catch((error: any) => {
//       Message.error('未知错误')
//     })
//     .finally(() => {
//       if (callback) {
//         callback()
//       }
//     })
// }
