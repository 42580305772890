import axios from 'axios'
import { MessageBox } from 'element-ui'

const isNewVersion = () => {
  const url = `//${window.location.host}/version.json?t=${new Date().getTime()}`
  axios.get(url).then(res => {
    if (res.status === 200) {
      const vueVersion = res.data.version
      const localVueVersion = localStorage.getItem('mainOperating')
      if (localVueVersion && localVueVersion !== vueVersion) {
        localStorage.setItem('mainOperating', vueVersion)
        MessageBox.alert('发现新版本，自动更新中...', {
          showClose: false
        })
        window.location.reload(true)
        return ''
      } else {
        localStorage.setItem('mainOperating', vueVersion)
      }
    }
  })
}

export default {
  isNewVersion
}