// 引入ElementUI组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import SvgIcon from 'vue-svgicon'
import plugins from '@/components'
import common from '@/mixin/common'
import * as util from '@/utils/util'
import * as directives from '@/directives'
import '@/assets/iconfont/iconfont.css'
// 全局初始化样式
import 'normalize.css'
// 全局样式
import '@/styles/index.scss'
// components/common里的组件
import com from '@/components/common'
// import fontSize from '@/utils/echarts-font'
// import "echarts-liquidfill"
// import * as directives from '@/directives'

const MyPlugin = {
  install: function (Vue) {
    // 全局注册一个宽度
    Vue.prototype.labelWidth = '120px'
    // 引入图表
    // Vue.prototype.$echarts = require('echarts')
    // 引入ui框架
    Vue.use(ElementUI)
    // 全局注册一些组件
    Vue.use(plugins)
    // 全局混入
    Vue.mixin(common)
    // 工具函数
    Vue.prototype.util = util
    // components/common里的组件
    Vue.use(com)
    Vue.use(SvgIcon, {
      tagName: 'svg-icon',
      defaultWidth: '1em',
      defaultHeight: '1em'
    })
    // 自定义指令
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })

    // Vue.prototype.$fontSize = fontSize
    // 自定义指令v-has
    // Object.keys(directives).forEach(key => {
    //   Vue.directive(key, directives[key])
    // })

    // 全局注册common文件夹下组件
    // Vue.use(com)

    // 全局静态数据
    // Vue.prototype.comData = comData
  }
}
export default MyPlugin
