import Cookies from 'js-cookie'

// 设置菜单折叠
const sidebarStatusKey = 'sidebar_status'
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(sidebarStatusKey, sidebarStatus)

// User -> token
const tokenKey = 'access_operation_token'
export const getTokenCookie = () => localStorage.getItem(tokenKey)
export const setTokenCookie = (token: string) => localStorage.setItem(tokenKey, token)
export const removeTokenCookie = () => localStorage.removeItem(tokenKey)

// //User -> current
// const currentKey = 'current'
// export const getCurrentCookie = () => Cookies.get(currentKey)
// export const setCurrentCookie = (data: string) => Cookies.set(currentKey, data)
// export const removeCurrentCookie = () => Cookies.remove(currentKey)

// User -> login_record
// const recordKey = 'login_record'
export const getLoginRecordCookie = () => Cookies.get(tokenKey)
export const setLoginRecordCookie = (data: string) => Cookies.set(tokenKey, data, { expires: 365, path: '' })
export const removeLoginRecordCookie = () => Cookies.remove(tokenKey)
