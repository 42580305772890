<template>
  <el-descriptions class="margin-top" :column="2"  border title="设备信息" label-class-name="my-label"
    content-class-name="my-content">
    <el-descriptions-item label="设备类型">{{ data.device.typeName }}</el-descriptions-item>
    <el-descriptions-item label="生产厂商">{{ data.device.businessName }}</el-descriptions-item>
    <el-descriptions-item label="出厂编号">{{ data.device.deviceNo }}</el-descriptions-item>
    <el-descriptions-item label="规格型号">{{ data.device.deviceModel }}</el-descriptions-item>
    <el-descriptions-item label="制造许可证编号">{{ data.device.licenseNumber }}</el-descriptions-item>
    <!-- data.device.deliveryTime -->
    <el-descriptions-item label="出厂日期">{{ util.getDate(data.device.deliveryTime) }}</el-descriptions-item>
    <el-descriptions-item label="生产使用年限">{{ data.device.useLife }}年</el-descriptions-item>
    <el-descriptions-item label="产品合格证">
      <fileListShowDownload :fileList='data.device.qaFiles' title="产品合格证"></fileListShowDownload>
    </el-descriptions-item>
    <el-descriptions-item label="制造许可证">
      <fileListShowDownload :fileList='data.device.licenseFiles' title="制造许可证"></fileListShowDownload>
    </el-descriptions-item>
    <template v-if="show">
      <el-descriptions-item label="设备备案机关">{{ data.record.recordUnit }}</el-descriptions-item>
      <el-descriptions-item label="产权/租赁单位">{{ data.device.propertyUnit }}</el-descriptions-item>
      <el-descriptions-item label="备案编号">{{ data.record.recordNo }}</el-descriptions-item>
      <el-descriptions-item label="备案日期">{{ data.record.recordDate }}</el-descriptions-item>
      <el-descriptions-item label="备案有效期">{{ data.record.validityDate }}</el-descriptions-item>
      <el-descriptions-item label="备案证图片">
        <fileListShowDownload :fileList='data.record.recordFiles' title="备案证图片"></fileListShowDownload>
      </el-descriptions-item>
    </template>
    <template v-if="projectShow">
      <el-descriptions-item label="使用登记机关">{{ data.useRegisterUnit }}</el-descriptions-item>
      <el-descriptions-item label="项目名称">{{ data.projectName }}</el-descriptions-item>
    </template>
  </el-descriptions>
</template>

<script>
export default {
  name: 'DeviceInfo',
  props: {
    data: {
      required: true
    },
    show: {
      type: Boolean,
      default: true
    },
    projectShow: {
      type: Boolean,
      default: false
    }

  },
}
</script>

<style lang="scss" scoped></style>