<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="item.path">
        <span v-if="item.redirect === 'noredirect' || index === breadcrumbs.length-1" class="no-redirect">{{
        item.meta.title }}</span>
        <a v-else  @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>

export default {
  name: 'BreadcrumbIndex',
  computed: {
    menu() {
      const menu = this.$store.getters['optionsMenu/getMenuinfo']
      return menu;
    },
  },
  data() {
    return {
      breadcrumbs: []
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(
        item => item.meta && item.meta.title
      )
      const first = matched[0]
      if (!this.isDashboard(first)) {
        matched = [
          { path: '/', meta: { title: '首页' } }
        ].concat(matched)
      }
      this.breadcrumbs = matched.filter(item => {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false
      })
    },
    isDashboard(route) {
      const name = route && route.meta && route.meta.title
      return name === '首页'
    },
    handleLink(item) {
      const { redirect } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push('/')
    }
  },
  watch: {
    '$route'() {
      this.getBreadcrumb()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
