<template>
  <div class="paged-box">
    <el-pagination :current-page.sync="currentPage" :page-sizes.sync="paginations.pageSizes" :page-size.sync="pageSize"
      :layout="paginations.layout" :total.sync="paginations.total" v-bind="$attrs" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
  </div>
</template>

<script>
export default {
  name: 'PaginaTion',
  inheritAttrs: false,
  props: {
    pagination: {
      type: Object,
      default() {
        return {
          total: 0,
          page: 1,
          size: 20,
          pageSizes: [10, 20, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper'
        }
      }
    }
  },
  computed: {
    paginations() {
      return this.pagination;
    },
    currentPage: {
      get() {
        return this.paginations.page
      },
      set(val) {
        this.paginations.page = val
      }
    },
    pageSize: {
      get() {
        return this.paginations.size
      },
      set(val) {
        this.paginations.size = val
      }
    }
  },
  methods: {
    handleSizeChange() {
      this.paginations.page = 1
      this.$emit('getList')
    },
    handleCurrentChange() {
      this.$emit('getList')
    }
  }
}
</script>

<style scoped>
</style>
