import router from '@/router/index.ts'
import { getTokenCookie } from '@/utils/cookies'
import { Message } from 'element-ui'
import store from '@/store/index.js'
import { UserModule } from '@/store/modules/user'
import versionTood from '@/versionUpdate.js'
router.beforeEach(async (to, from, next) => {
  window.name = 'main'
  Message.closeAll()
  // 判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion()
  // 判断有没有token,有的话执行里面的,没有的话执行else
  if (getTokenCookie()) {
    // 判断是否跳向登入相关页面,如果是重定向到首页
    if (to.path === '/login') {
      next('/')
    } else {
      // 调用方法获取用户信息,菜单,字典,
      await UserModule.getUser()

      // 在登录的情况下这几个页面任何时候都允许访问
      if (to.path === '/') {
        next()
      } else {
        // next()
        const menuinfo = store.getters['optionsMenu/getMenuinfoArr'];
        // 判断是否存在这个路由,不存在则提示并跳回首页
        if (menuinfo.find(x => x.url === to.path) == null) {
          Message.error('当前登录用户无权限访问此页面')
          next({ path: '/' })
        } else {
          next()
        }
      }
    }
  } else {
    // 判断去哪里,登入页,面登入页面直接放行,其他页面直接跳到登入页面去.
    if (to.path.indexOf('/login') > -1) {
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach((to) => {
  //  set page title
  if (to != null && to.meta != null && to.meta.title !== undefined && to.meta.title != null) {
    document.title = to.meta.title + ' - 益阳市智慧工地动态监管平台'
  } else {
    document.title = '益阳市智慧工地动态监管平台'
  }
})