<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" destroy-on-close>
    <slot />
  </el-dialog>
</template>
<script>
export default {
  name: 'ComDialog',
  methods: {}
}
</script>
