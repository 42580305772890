<template>
  <div class="menuBox">
    <template v-for="(item, index) in data">
      <template v-if="item.show && item.children.length > 0">
        <el-submenu :index="item.url" :key="index">
          <template slot="title">
            <el-image :src="item.icon" fit="fill" v-if="item.icon"></el-image>
            <span slot="title">{{ item.name }}</span>
          </template>
          <SidebarItem :data="item.children"></SidebarItem>
        </el-submenu>
      </template>
      <el-menu-item v-if="item.show && item.children.length == 0 " :index="item.url" class="home" :key="index">
        <el-image :src="item.icon" fit="fill" v-if="item.icon"></el-image>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: ['data'],
  computed: {
  },
}
</script>

<style scoped>
.el-image {
  width: 14px;
  height: 14px;
}

span {
  margin-left: 14px;
}

.el-menu-item:hover,
:deep(.el-submenu__title:hover) {
  background-color: #212C3B !important;
}
</style>