export default {
  namespaced: true,
  actions: {
    // 获取用户信息并设置

    // 获取权限信息并设置

  },
  mutations: {
    setuserInfo(state, value) {
      state.userInfo = value;
    }
  },
  state: {
    userInfo: {
    },
  },
  getters: {
    getuserInfoArr(state) {
      return Object.keys(state.userInfo);
    },
    getUserUnitType(state) {
      return state.userInfo?.unitType
    },
    getUserExtra(state) {
      return state.userInfo?.extra
    },
    getUserUnitId(state) {
      return state.userInfo?.unitId
    },
    getUserUnitName(state) {
      return state.userInfo.unitName
    }
  }
}