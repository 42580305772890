<template>
  <div class="navbar">
    <div @click="fold" class="hamburger-container">
      <i :class="iscollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
    </div>
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    <div class="right-menu">
      <div class="account">姓名：{{ userInfo.realname }}</div>
      <div class="account">手机号码：{{ userInfo.unitName }}</div>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar + '?imageView2/1/w/20/h/20'" class="user-avatar" alt="用户头像" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <span @click="logout">退出系统</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getTokenCookie } from '@/utils/cookies'
import { mapState } from 'vuex'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import { exit } from '@/api/users-service'

export default {
  name: 'headBar',
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapState('optionsUser', ['userInfo']),
    iscollapse() {
      if (this.$store.state.cookies) {
        return this.$store.state.cookies.sidebar_status
      }
      return false
    },
  },
  data() {
    return {
      avatar: ''
    }
  },
  methods: {
    async logout() {
      await exit({ accessToken: getTokenCookie().slice(7) });
      UserModule.Logout()
      this.$router.push('/login')
    },
    fold() {
      this.$store.dispatch('cookies/setSidebarStatus')
      console.log('点击了');
    },
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    padding: 0 15px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    // line-height: 50px;

    &:focus {
      outline: none;
    }

    .account {
      display: inline-block;
      padding: 0 6px;
      vertical-align: middle;
      height: 50px;
      /* line-height: 50px; */
      font-size: 14px;
      color: #333;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 10px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.el-dropdown-menu__item {
  padding: 0;

  span {
    display: block;
    padding: 0 20px;
  }
}

.el-dropdown-menu__item--divided:before {
  height: 0;
}
</style>
