export default {
  namespaced: true,
  state: {
    deviceStatus: "",
    flag: false,
    infoLiftId: null,
    infoId: null,
    ID: null,
    dialogFlag: false,
    projectId: null,
    dataType: null,
    contractingUnit: null,
    operate: false,
    deviceNo: null,
  },
  actions: {
    SET_STATE: ({ commit }, payload) => {
      commit("SET_STATE", payload);
    },
  },
  mutations: {
    SET_STATE: (state, { k, v }) => {
      state[k] = v;
    },
  },
};
