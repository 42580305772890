export default {
  namespaced: true,
  state: {
    deviceStatus: "",
    // flag: false,
    // infoLiftId: null,
    // infoId: null,
    state: null,
    ID: null,
    operate: false,
    propertyUnit: null, //详情 产权/租赁单位
    newUnit: null, // 产权变更  产权/租赁单位
    deviceNo: null, //出厂编号
    handOver: null, //是否进行过移交产权
  },
  actions: {
    SET_STATE: ({ commit }, payload) => {
      commit("SET_STATE", payload);
    },
  },
  mutations: {
    SET_STATE: (state, { k, v }) => {
      state[k] = v;
    },
  },
};
