// 获取当前文件夹下的的组件
// require.context 三个参数  第一个表示读取文件的路径  第二个表示是否遍历文件的子目录   第三个表示匹配的文件的正则
const requireComponent = require.context('/', true, /\.vue$/)

function plugins(Vue) {
  requireComponent.keys().forEach(fileName => {
    const config = requireComponent(fileName)
    Vue.component(config.default.name, config.default || config)
  })
}
export default plugins
