import request from '@/utils/request'

export const getTeams = (page, size, projectId, corporationId, type, name) => {
  let query = `?page=${page}&size=${size}`
  if (projectId) {
    query += '&projectId=' + projectId
  }
  if (corporationId) {
    query += '&corporationId=' + corporationId
  }
  if (type) {
    query += '&type=' + type
  }
  if (name) {
    query += '&name=' + name
  }
  return request({
    url: `/realname/team/app/teams${query}`,
    method: 'get'
  })
}

// 获取参建单位列表
export const getCorporations = (page, size, projectId, type, enterpriseId) => {
  let query = `?page=${page}&size=${size}`
  if (projectId) {
    query += `&projectId=${projectId}`
  }
  if (type) {
    query += `&type=${type}`
  }
  if (enterpriseId) {
    query += `&enterpriseId=${enterpriseId}`
  }
  return request({
    url: `/realname/corporation/list${query}`,
    method: 'get'
  })
}

export const getProjects = (data) => {
  return request({
    url: '/realname/project/list',
    method: 'get',
    params: data
  })
}