import request from "@/utils/request";

export const getRegions = (parentId) =>
  request({
    url: "/share/region/query/" + parentId,
    method: "get",
  });

export const regionQueryApp = (open) =>
  request({
    url: "/share/region/queryApp?open=" + open,
    method: "get",
  });
