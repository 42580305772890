<template>
  <div>
    <el-select v-model="forms[property]" :disabled="disabled" filterable remote clearable :placeholder="placeholder"
      :remote-method="selectUser" style="width:100%">
      <el-option v-for="item in users" :key="item.userId" :label="item.realname + item.cellphone"
        :value="item.userId" />
    </el-select>
  </div>
</template>
<script>
import { getUserListByCellphone } from '@/api/components/Select'
export default {
  name: 'SelectPersonalForm',
  props: {
    // 传递的数据
    form: {

    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    property: {
      type: String,
      default: () => {
        return 'userId'
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return '请输入最少5位手机号模糊查询'
      }
    },
  },
  computed: {
    forms() {
      return this.form
    }
  },
  data() {
    return {
      loading: true,
      users: []
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      if (this.form && this.form.cellphone) {
        this.selectUser(this.form.cellphone)
      }
    },

    async selectUser(cellphone) {
      if (!cellphone || cellphone.length < 5) {
        return false
      }
      const result = await getUserListByCellphone(cellphone)
      this.users = result.data
    }
  }
}
</script>

<style scoped>

</style>
