import { UserModule } from '@/store/modules/user'
import { getSidebarStatus, setSidebarStatus, getTokenCookie } from '@/utils/cookies'

export default {
  namespaced: true,
  actions: {
    // 获取token对比
    async setAccessToken(context) {
      const token = getTokenCookie()
      // 如果没有给他设置
      if (!context.state.access_operation_token) {
        context.commit('setAccessToken', token);
      } else if (context.state.access_operation_token !== token) {
        context.commit('setAccessToken', token);
        await UserModule.getUser(true)
      }
    },
    // 获取菜单是否折叠
    getSidebarStatus(context) {
      const res = getSidebarStatus();
      if (!res) {
        console.log(res)
        return
      }
      if (JSON.parse(res) !== Boolean(context.state.sidebar_status)) {
        console.log('每次调用了')
        context.commit('setSidebarStatus', JSON.parse(res));
      }
    },
    // 主动设置菜单折叠
    setSidebarStatus(context) {
      setSidebarStatus(!context.state.sidebar_status);
      context.commit('setSidebarStatus', !context.state.sidebar_status);
      setTimeout(() => {
        const myEvent = new Event('resize')
        window.dispatchEvent(myEvent)
      }, 500)
    }
  },
  mutations: {
    setAccessToken(state, value) {
      state.access_operation_token = value;
    },
    setSidebarStatus(state, value) {
      state.sidebar_status = value;
    },
  },
  state: {
    access_operation_token: '',
    sidebar_status: false,
  },
}