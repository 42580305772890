export default {
  namespaced: true,
  actions: {
  },
  mutations: {
    setteamInfo(state, value) {
      state.teamInfo = value;
    }
  },
  state: {
    teamInfo: []
  },
  getters: {
    getTeamInfo(state) {
      return state.teamInfo
    }
  }
}