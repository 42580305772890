<template>
  <router-view :key="single" />
</template>

<script>
export default {
  name: 'OperatingAppMain',
  computed: {
    single() {
      return this.$route.path
    }
  },
  data() {
    return {
    }
  }

}
</script>

<style lang="scss" scoped>

</style>