
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getRegions } from '@/api/components/select-address'

@Component({
  name: 'SelectAddress'
})
export default class extends Vue {
  @Prop({ default: null }) form!: any
  loading = true
  provinces: any = []
  citys: any[] = []
  districts: any[] = []

  created() {
    this.loadProvinces()
  }

  private async loadProvinces() {
    const result = await getRegions(0)
    this.provinces = result.data
  }

  private async provinceChange() {
    if (this.form.provinceId) {
      const result = await getRegions(this.form.provinceId)
      this.citys = result.data
    } else {
      this.citys = []
    }
    this.districts = []
    this.$set(this.form, 'cityId', '')
    this.$set(this.form, 'districtId', '')
    this.$emit('provinceChange', this.form.provinceId)
  }

  private async cityChange() {
    if (this.form.cityId) {
      const result = await getRegions(this.form.cityId)
      this.districts = result.data
    } else {
      this.districts = []
    }
    this.$set(this.form, 'districtId', '')
    this.$emit('cityChange', this.form.cityId)
  }

  districtChange() {
    this.$emit('districtChange', this.form.districtId)
  }
}
