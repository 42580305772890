export default {
  namespaced: true,
  actions: {

  },
  mutations: {
    setMenuinfo(state, value) {
      state.menuinfo = value;
    },
    setMenuArr(state, value) {
      state.menubtnArr = value;
    },
    setMenuinfoArr(state, value) {
      state.menuinfoArr = value;
    }
  },
  state: {
    menuinfo: [],
    menuinfoArr: [],
    menubtnArr: [],
  },
  getters: {
    getMenuinfo(state) {
      return state.menuinfo
    },
    getMenuinfoArr(state) {
      return state.menuinfoArr
    },
    getHomeMenuinfo(state) {
      return state.menuinfo[0]
    },
  }
}