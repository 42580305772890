<template>
  <div class="el-select">
    <el-select v-model="forms.creater" filterable remote clearable placeholder="请选择绑定企业" v-bind="$attrs"
      v-on="$listeners">
      <el-option v-for="item in enterprises" :key="item.unitId" :label="item.unitName" :value="item.unitId" />
    </el-select>
  </div>
</template>

<script>
import { getSelectEnterpriseList } from '@/api/components/select-enterprise';
export default {
  name: 'SelectEnterprises',
  props: {
    form: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    forms() {
      return this.form;
    }
  },
  data() {
    return {
      enterprisesId: this.value,
      enterprises: []
    }
  },
  created() {
    this.loadOptions();
  },
  methods: {
    async loadOptions() {
      const rs = await getSelectEnterpriseList();
      this.enterprises = rs.data;
    },
  }
}
</script>

<style scoped>

</style>
