<template>
  <div>
    <div
      v-for="item in fileList"
      :key="item"
      class="title-box"
      @click="download(item)"
    >
      <span class="title">{{ title }}{{ getFileName(item) }}</span>
    </div>
    <el-image-viewer
      style="z-index: 8888"
      v-if="innerVisible"
      :on-close="
        () => {
          innerVisible = false;
        }
      "
      :url-list="fileList"
    />
  </div>
</template>

<script>
export default {
  name: "fileListShowDownload",
  props: {
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
    },
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      innerVisible: false,
      uploadType: [".png", ".jpg", ".jpeg"],
      initialIndex: 0,
    };
  },
  methods: {
    getFileName(contentDisposition) {
      if (!contentDisposition) {
        return;
      }
      return contentDisposition.substring(contentDisposition.lastIndexOf("."));
    },
    closeImageViewer() {
      this.innerVisible = false;
    },
    async download(file) {
      const suffix = file.substring(file.lastIndexOf("."), file.length);
      if (this.uploadType.indexOf(suffix) != -1) {
        this.innerVisible = true;
        return false;
      }
      // 下载
      const downloadElement = document.createElement("a");
      downloadElement.href = file;
      downloadElement.target = "_blank";
      // downloadElement.download = `${name}${suffix}` // 下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  display: block;
  color: #1890ff;
  cursor: pointer;
}

.title-box {
  margin-bottom: 5px;
}
</style>
