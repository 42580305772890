import { removeTokenCookie, setTokenCookie } from '@/utils/cookies'
import { getMyTreeList } from '@/api/menu-service'
import { getCurrent } from '@/api/users-service'
import { getOptions } from '@/api/option-service'
// import { getProjects } from '@/api/project-service'
import * as util from '@/utils/util.ts'
import store from '@/store/index.js'
const getModule = {
  // 设置token
  setToken(token) {
    setTokenCookie('Bearer ' + token)
  },
  // 用户退出 删除token
  Logout() {
    removeTokenCookie()
    // 删除用户信息
    store.commit('optionsUser/setuserInfo', {})
    // 删除相关菜单
    store.commit('optionsMenu/setMenuinfo', [])
    store.commit('optionsMenu/setMenuinfoArr', [])
    // 删除字典信息
    store.commit('optionsnews/setnewsinfo', {})
    // 删除项目信息
    // store.commit('optionsProject/setProjectList', [])
    // 删除token信息
    store.commit('cookies/setAccessToken', '')
  },
  // 获取用户相关的数据
  async getUser(value = false) {
    // token是否一致
    store.dispatch('cookies/setAccessToken');
    // 获取用户信息
    const userInfo = store.getters['optionsUser/getuserInfoArr'];
    if (value || !userInfo || userInfo.length === 0) {
      console.log('获取用户信息')
      // 设置用户信息
      getCurrent().then(res => {
        store.commit('optionsUser/setuserInfo', res.data)
      })
    }
    // 获取用户菜单
    const menuinfo = store.getters['optionsMenu/getMenuinfoArr'];
    // 判断有没有用户菜单,是否强制更新菜单
    if (value || !menuinfo || menuinfo.length === 0) {
      const res = await getMyTreeList()
      // 存储树形菜单到store
      store.commit('optionsMenu/setMenuinfo', res.data)
      // 存储数组菜单到store
      const menusList = util.treeToList(res.data.menuList)
      store.commit('optionsMenu/setMenuinfoArr', menusList)
      const butRoles = []
      if (menusList && menusList.length > 0) {
        for (const menu of menusList) {
          if (menu.buttonList && menu.buttonList.length > 0) {
            butRoles.push.apply(butRoles, menu.buttonList)
          }
        }
      }
      store.commit('optionsMenu/setMenuArr', butRoles)
    }
    // 获取字典
    const shareinfo = store.getters['optionsShare/getShareinfoArr'];
    if (!shareinfo || shareinfo.length === 0) {
      // 获取字典信息
      getOptions().then(res => {
        store.commit('optionsShare/setShareinfo', res.data)
      })
    }
  },
}
export const UserModule = getModule
