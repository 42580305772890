import request from '@/utils/request'
import requestlogein from '@/utils/requestlogein'
// 用户相关的接口
// params

/**
 * 获取手机验证码
 * @param mobile
 * @returns {AxiosPromise}
 */
export const postLoginCaptcha = (params) =>
  request({
    // url: '/auth/code/sms',
    url: '/user/code/sms',
    method: 'get',
    params
  })

/**
* 获取获取token 验证码
* @param data
* @returns {AxiosPromise}
*/
export const postLogin = (data) =>
  request({
    url: '/auth/oauth/login',
    method: 'post',
    data
  })
/**
* 获取获取token
* @param data
* @returns {AxiosPromise}
*/
export const oauthToken = (data) =>
  requestlogein({
    url: '/oauth/token',
    method: 'post',
    data
  })
/**
 * 获取登入用户信息
 * @param data
 * @returns {AxiosPromise}
 */
export const getCurrent = () =>
  request({
    url: '/user/getMyDetail',
    method: 'get'
  })

/**
 * 用户退出或注销
 * @param data
 * @returns {AxiosPromise}
 */
export const exit = (data) =>
  requestlogein({
    url: `/dologout`,
    method: 'post',
    data
  })