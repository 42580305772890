<template>
  <div class="el-select">
    <select-project-form v-model="forms.projectId" @change="projectChange" class="option-item" />
    <el-select v-model="forms.corporationId" clearable placeholder="请选择归属企业" class="option-item"
      @change="corporationChange" @clear="corporationClear">
      <el-option v-for="item in corporations" :key="item.id" :label="item.fullName || item.enterpriseText"
        :value="item.id">
        <span style="float: left">{{ item.fullName || item.enterpriseText }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.typeText }}</span>
      </el-option>
    </el-select>
    <el-select v-model="forms.teamId" clearable placeholder="请选择所属班组" @change="teamChange" @clear="teamClear"
      class="option-item">
      <el-option v-for="item in teams" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
  </div>
</template>
<script>
import { getTeams, getCorporations } from '@/api/components/project-unit-team'
export default {
  name: 'project-unit-team',
  props: {
    form: {
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      projects: [],
      corporations: [],
      teams: []
    }
  },
  computed: {
    forms() {
      return this.form
    }
  },

  methods: {
    async projectChange(value) {
      this.forms.projectId = value
      if (this.forms.projectId) {
        // 加载单位
        const cresult = await getCorporations(1, 1000, ~~this.forms.projectId, '')
        this.corporations = cresult.data.list
      } else {
        this.corporations = []
      }
      this.corporationClear()
    },
    // 参建单位选择事件
    async corporationChange(value) {
      this.forms.corporationId = value
      if (this.forms.corporationId > 0) {
        // 加载班组
        const tresult = await getTeams(1, 1000, ~~this.forms.projectId, this.forms.corporationId)
        this.teamClear()
        this.teams = tresult.data.list
      }
    },
    async corporationClear() {
      this.teamClear()
      this.$set(this.form, 'corporationId', '')
      this.teams = []
    },
    teamChange(value) {
      this.forms.teamId = value
    },
    teamClear() {
      this.$set(this.form, 'teamId', '')
    }
  }
}
</script>

<style lang="scss">
.option-item {
  width: 170px;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
