import Vue from "vue";
import Vuex from "vuex";
import optionsUser from "@/store/modules/optionsUser";
import optionsMenu from "@/store/modules/optionsMenu";
import optionsShare from "@/store/modules/optionsShare";
import optionsTeam from "@/store/modules/optionsTeam";
// import optionsProject from "@/store/modules/optionsProject";
import cookies from "@/store/modules/cookies";
import operationEquipmentManagement from "@/store/modules/operationEquipmentManagement";
import propertyEquipment from "@/store/modules/propertyEquipment";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // 用户信息
    optionsUser,
    // 菜单信息
    optionsMenu,
    // 字典信息
    optionsShare,
    // 班组信息
    optionsTeam,
    // 项目信息
    // optionsProject,
    // cookies信息包含token,
    cookies,
    operationEquipmentManagement,
    propertyEquipment,
  },
});
