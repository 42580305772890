<template>
  <div class="el-select">
    <el-select v-model="forms.category" placeholder="请选择一级分类" filterable class="filter-item" clearable
      @change="provinceChange()">
      <el-option v-for="item in provinces" :key="item.id" :label="item.fullName" :value="item.id" />
    </el-select>
    <el-select v-model="forms.type" placeholder="请选择二级分类" filterable class="filter-item" clearable
      @change="cityChange()">
      <el-option v-for="item in citys" :key="item.id" :label="item.fullName" :value="item.id" />
    </el-select>
    <el-select v-model="forms.kind" placeholder="请选择三级分类" class="filter-item" filterable clearable
      @change="districtChange">
      <el-option v-for="item in districts" :key="item.id" :label="item.fullName" :value="item.id" />
    </el-select>
  </div>
</template>

<script >
import { getRegions } from '@/api/components/select-classify'
export default {
  name: 'SelectAddress',
  props: {
    form: {
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      loading: true,
      provinces: [],
      citys: [],
      districts: []
    }
  },
  computed: {
    forms() {
      return this.form
    }
  },
  mounted() {
    const unwatch = this.$watch('form', function () {
      console.log('调用了@@@@@@@@@@@@')
      this.provinceChange(true)
      this.cityChange(true)
      unwatch()
    })
  },
  created() {
    this.loadProvinces()
  },
  methods: {

    async loadProvinces() {
      const result = await getRegions(0)
      this.provinces = result.data
    },
    async provinceChange(val) {
      console.log(val)
      if (this.forms.category) {
        const result = await getRegions(this.forms.category)
        this.citys = result.data
      } else {
        this.citys = []
      }
      this.districts = []
      if (!val) {
        this.$set(this.forms, 'type', '')
        this.$set(this.forms, 'kind', '')
      }
      this.$emit('provinceChange', this.forms.category)
    },

    async cityChange(val) {
      if (this.forms.type) {
        const result = await getRegions(this.forms.type)
        this.districts = result.data
      } else {
        this.districts = []
      }
      if (!val) {
        this.$set(this.forms, 'kind', '')
      }
      this.$emit('cityChange', this.forms.type)
    },

    districtChange() {
      this.$emit('districtChange', this.forms.kind)
    }
  }

}
</script>

<style scoped>

</style>
