<template>
  <div class="el-select">
      <el-input v-model="projectName" placeholder="请选择项目" @focus="focus" :clearable="clearable"
          :disabled="dialogVisible || status" @clear="clear">
      </el-input>
      <el-input v-model="model" placeholder="请选择id" v-show="false"></el-input>
      <el-dialog title="选择项目" :visible.sync="dialogVisible" width="800px" append-to-body center>
          <div class="dialogheight">
              <div class="filtrate">
                  <el-input v-model="filtrate.projectName" placeholder="请输入项目名称" clearable></el-input>
                  <el-input v-model="filtrate.projectCode" placeholder="请输入项目编码" clearable></el-input>
                  <el-select v-model="filtrate.status" placeholder="请选择项目状态" clearable class="filter-item-select">
                      <el-option v-for="item in projectState" :key="item.code" :label="item.name" :value="item.code" />
                  </el-select>
                  <el-button type="primary" @click="seve" icon="el-icon-search">
                      查询
                  </el-button>
              </div>
              <el-table :data="tableData" stripe v-loading="loading" height="flex:1" lement-loading-text="Loading" border
                  fit :cell-style="{ padding: '5px 0' }" :highlight-current-row="true" :row-class-name="() => 'present'"
                  :header-cell-style="{ backgroundColor: '#f5f9f8' }" @row-click="pitchon">
                  <el-table-column type="index" label="序号" :index="indexMethod" fixed align="center" width="80" />
                  <el-table-column prop="extCode" label="项目编码" width="200px" align="center">
                  </el-table-column>
                  <el-table-column prop="fullName" label="项目名称" align="center">
                  </el-table-column>
              </el-table>
          </div>
          <el-pagination :current-page.sync="condition.page" :page-sizes="[10, 20, 50, 100]" :pager-count="5"
              :page-size.sync="condition.size" layout="total, sizes, prev,pager, next, jumper" :total.sync="total"
              @current-change="_getProjectSpringFrame" @size-change="_getProjectSpringFrame" />

      </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getProjectSpringFrame } from "@/api/components/Select.js"
export default {
  props: {
    value: {
      default: ''
    },
    disabled: {

    },
    clearable: {
      default: true
    },
    groupId: {
      default: 1
    }
  },
  data() {
    return {
      loading: false,
      projectName: '',
      inputs: "",
      filtrate: {

      },
      dialogVisible: false,
      tableData: [],
      condition: {
        page: 1,
        size: 10
      },
      total: 0,
    }
  },
  computed: {
    ...mapState("optionsShare", ["shareinfo"]),
    projectState() {
      return this.shareinfo.projectState;
    },
    status() {
      if (this.disabled === undefined) {
        return false;
      }
      if (this.disabled === '') {
        return true;
      }
      return Boolean(this.disabled)
    },
    model: {
      get() {
        return this.value || null
      },
      set(newVal) {
        const label = this.projects.find(item => {
          return item.id === newVal
        })
        if (label) {
          this.$emit('select', label.fullName)
        } else {
          this.$emit('select', '')
        }
        this.$emit('input', newVal)
        this.$emit('change', newVal)
      }
    }
  },
  created() {
    this.$set(this.condition, 'groupId', this.groupId)
  },
  methods: {
    async getProjectSpringList(valid) {
      this.projectName = ''
      await getProjectSpringFrame({ id: valid, groupId: this.groupId }).then((res) => {
        this.projects = res.data.list
        if (valid && !this.projectName) {
          const label = this.projects?.find(item => {
            return item.id === valid
          })
          if (label) {
            this.projectName = label.fullName;
          }
        }
      })
    },
    clear() {
      this.model = '';
    },
    pitchon(val) {
      this.projectName = val.fullName;
      this.$emit('select-project-spring-frame',val);
      this.model = val.id;
      this.dialogVisible = false;
    },
    seve() {
      Object.assign(this.condition, this.filtrate)
      this.condition.page = 1;
      this._getProjectSpringFrame()
    },
    focus() {
      this.dialogVisible = true;
      this._getProjectSpringFrame()
    },
    async _getProjectSpringFrame() {
      this.loading = true;
      const res = await getProjectSpringFrame(this.condition)
      this.tableData = res.data.list;
      this.total = res.data.totalCount;
      this.loading = false;
    }
  },
  watch: {
    value: {
      handler() {
        this.getProjectSpringList(this.value)
      },
      immediate: true,
    },
  }
}
</script>
<style scoped>
:deep(.el-input__validateIcon) {
  display: none
}

.dialogheight {
  height: 500px;
  display: flex;
  flex-direction: column;
}

.el-table {
  flex: 1;
  margin: 20px 0;
}

.filtrate {
  display: flex;
}

.filtrate>.el-input {
  margin-right: 10px;
}

:deep(.el-table__body :hover) {
  cursor: pointer;
  background-color: rgb(244, 245, 245);
}
.filter-item-select{
  width: 100%;
  margin-right: 10px;
}
</style>
