import request from "@/utils/request";
//产权设备-统计接口
const type = "/iotlift";
export const getStatistics = (params) => {
  return request({
    url: `${type}/device/property/statistics`,
    method: "get",
    params,
  });
};
//产权设备 - 列表接口
export const getList = (params) => {
  return request({
    url: `${type}/device/property/list`,
    method: "get",
    params,
  });
};
//产权设备 - 起重机详情
export const getInfo = (params) => {
  return request({
    url: `${type}/crane/info/${params}`,
    method: "get",
  });
};

//产权设备 - 升降机详情
export const getInfoHoist = (params) => {
  return request({
    url: `${type}/hoist/info/${params}`,
    method: "get",
  });
};
//起重机新增
export const setCraneSave = (data) => {
  return request({
    url: `${type}/crane/save`,
    method: "post",
    data,
  });
};
//升降机新增
export const setHoistSave = (data) => {
  return request({
    url: `${type}/hoist/save`,
    method: "post",
    data,
  });
};
//起重机修改
export const updateCraneSave = (data) => {
  return request({
    url: `${type}/crane/update`,
    method: "post",
    data,
  });
};

//起重机修改
export const updateHoistSave = (data) => {
  return request({
    url: `${type}/hoist/update`,
    method: "post",
    data,
  });
};
//产权设备 - 备案详情
export const getRecord = (params) => {
  return request({
    url: `${type}/record/nodes/${params}`,
    method: "get",
  });
};

//产权设备 - 备案详情 - 编辑 产权备案
export const getRecordDetail = (params) => {
  return request({
    url: `${type}/record/request/detail/${params}`,
    method: "get",
  });
};
//产权设备 - 备案详情 - 编辑-修改  产权备案
export const getRecordDetailUpdate = (data) => {
  return request({
    url: `${type}/record/request/update`,
    method: "post",
    data,
  });
};

//产权设备 - 备案详情 - 编辑-修改  产权变更
export const getChangeDetail = (params) => {
  return request({
    url: `${type}/record/change/detail/${params}`,
    method: "get",
  });
};
//产权设备 - 备案详情 - 编辑  产权变更
export const getChangeDetailUpdate = (data) => {
  return request({
    url: `${type}/record/change/update`,
    method: "post",
    data,
  });
};

//产权设备 - 备案详情 - 编辑-修改  产权变更
export const getLogOffDetail = (params) => {
  return request({
    url: `${type}/record/cancel/detail/${params}`,
    method: "get",
  });
};

// 操作记录
export const getOperationList = (data) => {
  return request({
    url: `${type}/operation/log/list`,
    method: "post",
    data,
  });
};

//产权设备 - 新增- 产权备案
export const setrecordSave = (data) => {
  return request({
    url: `${type}/record/request/save`,
    method: "post",
    data,
  });
};
//绑定使用单位
export const deviceBind = (data) => {
  return request({
    url: `${type}/device/property/use/bind`,
    method: "post",
    data,
  });
};

//移交产权
export const deviceHandOver = (data) => {
  return request({
    url: `${type}/device/property/handOver`,
    method: "post",
    data,
  });
};
///record/change/save

//产权变更 申请
export const recordSave = (data) => {
  return request({
    url: `${type}/record/change/save`,
    method: "post",
    data,
  });
};

//产权注销 申请
export const recordCancelSave = (data) => {
  return request({
    url: `${type}/record/cancel/save`,
    method: "post",
    data,
  });
};
//

export const getDevicel = (id) => {
  return request({
    url: `${type}/device/registration/${id}`,
    method: "get",
  });
};
/**
 * 企业单位下拉列表
 */
export const enterpriseList = (params) => {
  return request({
    url: "/enterprise/list",
    method: "get",
    params,
  });
};
/**
 * 政务中心-产权注销-详情
 */
export const recordDetail = (logId) => {
  return request({
    url: `/iotlift/record/cancel/detail/${logId}`,
    method: "get",
  });
};

/**
 * 政务中心-产权变更-详情
 */
export const changeDetail = (logId) => {
  return request({
    url: `/iotlift/record/change/detail/${logId}`,
    method: "get",
  });
};

/**
 * 政务中心-产权备案-详情
 */
export const requestDetail = (logId) => {
  return request({
    url: `/iotlift/record/request/detail/${logId}`,
    method: "get",
  });
};
