import Vue from 'vue'
// 只能输入整数
const LimitInt = Vue.directive('LimitInt', {
  bind(el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
})
// 只能输入number
const LimitNumber = Vue.directive('LimitNumber', {
  bind: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      if (charcode === 46) {
        if (el.value && el.value.includes('.')) {
          e.preventDefault()
        }
      } else if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
})

// onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
export { LimitInt, LimitNumber }
