<template>
  <el-dialog :width="width" :height="height || null" center :visible.sync="DialogVisible" :title="title || ''"
    :close-on-click-modal="false" :append-to-body="true">
    <template v-if="DialogVisible">
      <slot></slot>
    </template>

  </el-dialog>
</template>
<script>
export default {
  name: "dialogView",

  props: {
    width: {},
    title: {},
    height: {},
    visible: {
      // 对话框是否可见
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullScreen: false,
    };
  },
  computed: {
    DialogVisible: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("update:visible", false);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .enterprise-title {
  background-color: transparent !important;
}

// ::v-deep .el-dialog__header {
//   padding: 0px !important;
// }

::v-deep .el-dialog__body {
  // padding: 0px !important;
}
</style>