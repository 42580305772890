<template>
  <div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "paginationView",
  props: {
    currentPage: { default: () => 1 },
    total: { default: () => 0 },
    pageSizes: { default: () => [10, 20, 50, 100] },
    pageSize: { default: () => 10 },
  },
  methods: {
    handleSizeChange(val) {
      if (this.$listeners.handleSizeChange) {
        this.$emit("handleSizeChange", val);
      }
    },
    handleCurrentChange(val) {
      if (this.$listeners.handleCurrentChange) {
        this.$emit("handleCurrentChange", val);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
