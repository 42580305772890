import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: () => import('@/views/AboutView/index.vue'),
        meta: {
          title: '首页',
        }
      },
      {
        path: '/user/personal',
        component: () => import('@/views/personal/index.vue'),
        meta: {
          title: '个人用户',
        }
      },
      {
        path: '/user/administration',
        component: () => import('@/views/administration/index.vue'),
        meta: {
          title: '行政单位'
        }
      },
      {
        path: '/user/organizationChart',
        component: () => import('@/views/organizationChart/index.vue'),
        meta: {
          title: '行政机构'
        }
      },
      {
        path: '/enterprises/enterprise',
        component: () => import('@/views/enterprise/item.vue'),
        meta: {
          title: '企业列表'
        }
      },
      {
        path: '/enterprises/firmRealName',
        component: () => import('@/views/realname/index.vue'),
        meta: {
          title: '企业实名制情况'
        }
      },
      {
        path: '/project/index',
        component: () => import('@/views/project/index.vue'),
        meta: {
          title: '项目列表'
        }
      },
      ///itemGroup
      {
        path: '/itemGroup',
        component: () => import('@/views/itemGroup/index.vue'),
        meta: {
          title: '项目分组'
        }
      },
      {
        path: '/project/firmRealName',
        component: () => import('@/views/projectPersonnel/index.vue'),
        meta: {
          title: '项目实名人员'
        }
      },
      {
        path: '/project/key-position-person',
        component: () => import('@/views/keyPosition/index.vue'),
        meta: {
          title: '关键岗位人员列表'
        }
      },
      {
        path: '/person/in-out-info',
        component: () => import('@/views/inout/index.vue'),
        meta: {
          title: '进退场记录'
        }
      },
      {
        path: '/person/attendance-details-query',
        component: () => import('@/views/attendanceDetail/index.vue'),
        meta: {
          title: '考勤明细'
        }
      },
      {
        path: '/person/xy-index',
        component: () => import('@/views/educationalTraining/index.vue'),
        meta: {
          title: '教育培训记录'
        }
      },
      {
        path: '/person/contract',
        component: () => import('@/views/contractAdministration/index.vue'),
        meta: {
          title: '合同管理'
        }
      },
      {
        path: '/person/salary',
        component: () => import('@/views/wageManagement/index.vue'),
        meta: {
          title: '工资发放管理'
        }
      },
      {
        path: '/person/work-attendance-by-moth-day',
        component: () => import('@/views/worker/index.vue'),
        meta: {
          title: '考勤统计'
        }
      },
      {
        path: '/person/attendance-project',
        component: () => import('@/views/attendanceDay/index.vue'),
        meta: {
          title: '项目日考勤统计'
        }
      },
      {
        path: '/person/project-attendance-by-month',
        component: () => import('@/views/attendanceMonth/index.vue'),
        meta: {
          title: '项目月考勤统计'
        }
      },
      {
        path: '/person/contract-sign',
        component: () => import('@/views/contractStatistics/index.vue'),
        meta: {
          title: '合同签订报表'
        }
      },
      {
        path: '/person/contracts',
        component: () => import('@/views/contractSigning/index.vue'),
        meta: {
          title: '合同签订'
        }
      },
      {
        path: '/person/train-project',
        component: () => import('@/views/educationStatistics/index.vue'),
        meta: {
          title: '教育培训统计表'
        }
      },
      {
        path: '/iot/mechanical',
        component: () => import('@/views/smart-mechanical/index.vue'),
        meta: {
          title: '起重设备列表'
        }
      },
      {
        path: '/iot/tower',
        component: () => import('@/views/smart-tower/index.vue'),
        meta: {
          title: '塔机监控设备列表'
        }
      },
      // {
      //   path: '/iot/lifter',
      //   component: () => import('@/views/smart-lifter/index.vue'),
      //   meta: {
      //     title: '升降机监控设备列表'
      //   }
      // },
      {
        path: '/iot/video',
        component: () => import('@/views/smart-video/item.vue'),
        meta: {
          title: '视频监控设备列表'
        }
      },
      {
        path: '/iot/environment',
        component: () => import('@/views/smart-environment/index.vue'),
        meta: {
          title: '环境监测设备列表'
        }
      },
      {
        path: '/iot/face',
        // component: () => import('@/views/smart-face/index.vue'),
        component: () => import('@/views/smart-face/item.vue'),
        meta: {
          title: '人脸考勤设备列表'
        }
      },
      {
        path: '/iot/issue',
        component: () => import('@/views/equipment-smart/index.vue'),
        meta: {
          title: '大华设备下发记录'
        }
      },
      {
        path: '/iot/unit',
        component: () => import('@/views/serviceUnitManagement/index.vue'),
        meta: {
          title: '服务单位管理',
        },
      },
      {
        path: '/iot/manufacturer',
        component: () => import('@/views/manufacturer/index.vue'),
        meta: {
          title: '服务单位管理'
        }
      },
      {
        path: '/content/advert',
        component: () => import('@/views/advert/index.vue'),
        meta: {
          title: '广告列表'
        }
      },
      {
        path: '/content/notice',
        component: () => import('@/views/notice/index.vue'),
        meta: {
          title: '公告列表'
        }
      },
      {
        path: '/content/information',
        component: () => import('@/views/dynamicInformation/index.vue'),
        meta: {
          title: '动态资讯'
        }
      },
      {
        path: '/content/assist',
        component: () => import('@/views/assist/index.vue'),
        meta: {
          title: '帮助中心'
        }
      },
      {
        path: '/content/information-category',
        component: () => import('@/views/category-listings/index.vue'),
        meta: {
          title: '资讯分类'
        }
      },
      {
        path: '/content/assist-category',
        component: () => import('@/views/assist-listings/index.vue'),
        meta: {
          title: '帮助分类'
        }
      },
      {
        path: '/permission/business',
        component: () => import('@/views/business/index.vue'),
        meta: {
          title: '运营后台菜单'
        }
      },
      {
        path: '/permission/site_manage',
        component: () => import('@/views/site_manage/index.vue'),
        meta: {
          title: '管理后台菜单'
        }
      },
      {
        path: '/permission/site_app',
        component: () => import('@/views/site_app/index.vue'),
        meta: {
          title: '管理APP菜单'
        }
      },
      {
        path: '/permission/role',
        component: () => import('@/views/role/index.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/permission/insurance',
        component: () => import('@/views/insurance/index.vue'),
        meta: {
          title: '保险后台菜单'
        }
      },
      {
        path: '/permission/application',
        component: () => import('@/views/application/index.vue'),
        meta: {
          title: '应用后台权限管理'
        }
      },
      {
        path: '/system/admin',
        component: () => import('@/views/admin/index.vue'),
        meta: {
          title: '管理员管理'
        }
      },
      {
        path: '/system/region',
        component: () => import('@/views/region/index.vue'),
        meta: {
          title: '行政区域'
        }
      },
      // {
      //   path: '/message/index',
      //   component: () => import('@/views/message/index.vue'),
      //   meta: {
      //     title: '消息管理列表'
      //   }
      // },
      {
        path: '/verification-code-sending-record',
        component: () => import('@/views/verification-code-sending-record/index.vue'),
        meta: {
          title: '验证码发送记录'
        }
      },
      {
        path: '/message/system',
        component: () => import('@/views/message-system/index.vue'),
        meta: {
          title: '系统消息'
        }
      },
      {
        path: '/message/trigger',
        component: () => import('@/views/message-trigger/index.vue'),
        meta: {
          title: '消息类型'
        }
      },
      {
        path: '/message/template',
        component: () => import('@/views/message-template/index.vue'),
        meta: {
          title: '消息模板'
        }
      },
      {
        path: '/antiepidemic/index',
        component: () => import('@/views/antiepidemic/index.vue'),
        meta: {
          title: '防疫信息列表'
        }
      },
      {
        path: '/mobile-inspection/index',
        component: () => import('@/views/mobile-inspection/index.vue'),
        meta: {
          title: '移动巡检列表'
        }
      },
      {
        path: '/mobile-inspection/trouble-list',
        component: () => import('@/views/trouble-list/index.vue'),
        meta: {
          title: '隐患类别'
        }
      },
      {
        path: '/mobile-inspection/data-statistics',
        component: () => import('@/views/data-statistics/index.vue'),
        meta: {
          title: '数据统计'
        }
      },
      {
        path: '/officialwebsite/information',
        component: () => import('@/views/information/index.vue'),
        meta: {
          title: '资讯列表'
        }
      },
      {
        path: '/officialwebsite/videolist',
        component: () => import('@/views/videolist/index.vue'),
        meta: {
          title: '视频列表'
        }
      },
      {
        path: '/officialwebsite/classify',
        component: () => import('@/views/classify/index.vue'),
        meta: {
          title: '分类管理'
        }
      },
      {
        path: '/officialwebsite/leaveword',
        component: () => import('@/views/leaveword/index.vue'),
        meta: {
          title: '留言列表'
        }
      },
      {
        path: '/interfaceManagement/classification',
        component: () => import('@/views/classification/index.vue'),
        meta: {
          title: '险种分类'
        }
      },
      {
        path: '/interfaceManagement/specification',
        component: () => import('@/views/specification/index.vue'),
        meta: {
          title: '设置规格'
        }
      },
      {
        path: '/interfaceManagement/label',
        component: () => import('@/views/label/index.vue'),
        meta: {
          title: '设置标签'
        }
      },
      {
        path: '/interfaceManagement/serviceProject',
        component: () => import('@/views/serviceProject/index.vue'),
        meta: {
          title: '设置服务项目'
        }
      },
      {
        path: '/interfaceManagement/list',
        component: () => import('@/views/typesInsurance/index.vue'),
        meta: {
          title: '险种列表'
        }
      },
      {
        path: '/interfaceManagement/client',
        component: () => import('@/views/consultingClientList/index.vue'),
        meta: {
          title: '咨询客户列表'
        }
      },
      {
        path: '/iot/conditionMonitoring',
        component: () => import('@/views/smart-equipment/index.vue'),
        meta: {
          title: '设备状态监控'
        }
      },
      {
        path: '/iot/environmentConditionMonitoring',
        component: () => import('@/views/smart-equipment-environment/index.vue'),
        meta: {
          title: '环境设备状态监控'
        }
      },
      {
        path: '/iot/attendanceSmartEquipment',
        component: () => import('@/views/smart-equipment-Attendance/index.vue'),
        meta: {
          title: '考勤设备状态监控'
        }
      },
      {
        path: '/iot/avideoSmartEquipment',
        component: () => import('@/views/smart-equipment-video/index.vue'),
        meta: {
          title: '视频设备状态监控'
        }
      },
      {
        path: '/iot/propertyEquipment',
        component: () => import('@/views/propertyEquipment/index.vue'),
        meta: {
          title: '产权设备库'
        }
      },
      {
        path: '/iot/operationEquipmentManagement',
        component: () => import('@/views/operationEquipmentManagement/index.vue'),
        meta: {
          title: '运行设备管理'
        }
      },
      {
        path: '/alarm/set',
        component: () => import('@/views/alarmSet/index.vue'),
        meta: {
          title: '告警设置'
        }
      },
      {
        path: '/alarm/journal',
        component: () => import('@/views/alarmJournal/index.vue'),
        meta: {
          title: '告警日志'
        }
      },
      {
        path: '/alarm/statistics',
        component: () => import('@/views/alarmStatistics/index.vue'),
        meta: {
          title: '告警统计'
        }
      },
      {
        path: '/system/reportingLogs',
        component: () => import('@/views/reportingLogs/index.vue'),
        meta: {
          title: '上报日志'
        }
      },
      {
        path: '/permission/abutment',
        component: () => import('@/views/permissionAbutment/index.vue'),
        meta: {
          title: '业务系统对接'
        }
      },
      {
        path: '/system/register',
        component: () => import('@/views/systemRegister/index.vue'),
        meta: {
          title: '登录日志'
        }
      },
      {
        path: '/system/passwordResetLog',
        component: () => import('@/views/passwordResetLog/index.vue'),
        meta: {
          title: '密码重置日志'
        }
      },
      {
        path: '/system/operationLog',
        component: () => import('@/views/systemOperationLog/index.vue'),
        meta: {
          title: '操作日志'
        }
      },
      {
        path: '/platform',
        component: () => import('@/views/platform/index.vue'),
        meta: {
          title: '平台设置'
        }
      },
      {
        path: '/reportEarlyWarning',
        component: () => import('@/views/reportEarlyWarning/index.vue'),
        meta: {
          title: '上报预警'
        }
      },
      {
        path: '/synchronousData',
        component: () => import('@/views/synchronousData/index.vue'),
        meta: {
          title: '同步数据'
        }
      },
      {
        path: '/realTimeReporting',
        component: () => import('@/views/realTimeReporting/index.vue'),
        meta: {
          title: '实时上报日志'
        }
      },
      {
        path: '/typeError',
        component: () => import('@/views/typeError/index.vue'),
        meta: {
          title: '错误类型管理'
        }
      },
      {
        path: '/warningDetail',
        component: () => import('@/views/warningDetail/index.vue'),
        meta: {
          title: '预警明细表'
        }
      },
      {
        path: '/devicePlanManagement',
        component: () => import('@/views/devicePlanManagement/index.vue'),
        meta: {
          title: '项目设备统计'
        }
      },
      {
        path: '/group',
        component: () => import('@/views/group/index.vue'),
        meta: {
          title: '班组管理'
        }
      },
      {
        path: '/defaultRoleConfiguration',
        component: () => import('@/views/defaultRoleConfiguration/index.vue'),
        meta: {
          title: '默认角色配置'
        }
      },
      {
        path: '/pushRule',
        component: () => import('@/views/pushRule/index.vue'),
        meta: {
          title: '推送规则'
        }
      },
      {
        path: '/pushLog',
        component: () => import('@/views/pushLog/index.vue'),
        meta: {
          title: '推送日志'
        }
      },
      // applicationAuthority 应用权限管理
      {
        path: '/applicationList',
        component: () => import('@/views/applicationList/index.vue'),
        meta: {
          title: '应用列表'
        }
      },
      {
        path: '/applicationMenu',
        component: () => import('@/views/applicationMenu/index.vue'),
        meta: {
          title: '应用菜单'
        }
      },
      {
        path: '/applicationEnterprise',
        component: () => import('@/views/applicationEnterprise/index.vue'),
        meta: {
          title: '企业应用权限管理'
        }
      },
      {
        path: '/applicationSupervise',
        component: () => import('@/views/applicationSupervise/index.vue'),
        meta: {
          title: '监管应用权限管理'
        }
      },
      {
        path: '/supervise',
        component: () => import('@/views/supervise/index.vue'),
        meta: {
          title: '项目监管责任人'
        }
      },
      {
        path: '/noElectronicAttendance/index',
        component: () => import('@/views/noElectronicAttendance/index.vue'),
        meta: {
          title: '未实施电子考勤项目'
        }
      },
      {
        path: '/itemSynchronization/index',
        component: () => import('@/views/itemSynchronization/index.vue'),
        meta: {
          title: '第三方项目同步'
        }
      },
      {
        path: '/versionLog',
        component: () => import('@/views/versionLog/index.vue'),
        meta: {
          title: '版本日志'
        }
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
