<template>
  <el-table
    :class="className"
    :data="tableData"
    v-loading="loading"
    :border="border"
    v-on="$listeners"
    v-bind="$attrs"
    ref="myTable"
    v-if="TableColumn && tableData"
  >
    <el-table-column type="index" :index="indexMethod" width="50" label="序号">
    </el-table-column>
    <columnView
      v-for="(item, index) in TableColumn"
      :column="item"
      :key="index"
    ></columnView>
  </el-table>
</template>

<script>
export default {
  name: "tableView",
  props: {
    className: {},
    TableColumn: {},
    tableData: { type: Array, require: true },
    border: { default: () => true },
    loading: { default: () => false },
    currentPage: { default: () => null },
    pageSize: { default: () => null },
  },
  mounted() {
    this.$refs.myTable && this.$refs.myTable.doLayout();
  },
  methods: {
    indexMethod(index) {
      if (this.currentPage && this.pageSize) {
        const currentPage = this.currentPage;
        const pageSize = this.pageSize;
        const prevLastIndex = (currentPage - 1) * pageSize;
        const truelndex = index + prevLastIndex;
        return truelndex >= currentPage * pageSize
          ? truelndex
          : index + prevLastIndex + 1;
      } else {
        return index + 1;
      }
    }, //序号
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row:hover {
  background-color: rgba($color: #f5f7fa, $alpha: 1);
}
</style>
