<template>
  <el-table-column
    :label="column?.label"
    :type="column?.type"
    :prop="column?.prop"
    :width="column?.width"
    :class-name="column?.className"
  >
    <template slot-scope="scope">
      <!-- 非操作列的处理 -->
      <template v-if="column?.label != '操作'">
        <!-- 状态 -->
        <template v-if="column?.type == '0'">
          <span
            :style="{
              color: column?.list?.find(
                (item) => item?.value == scope?.row[column?.prop]
              )
                ? column?.list?.find(
                    (item) => item?.value == scope?.row[column?.prop]
                  )?.color
                : null,
            }"
          >
            {{
              column?.list?.find(
                (item) => item?.value == scope?.row[column?.prop]
              )?.label
            }}</span
          >
        </template>
        <!-- Switch开关 -->
        <template v-else-if="column?.type == '1'">
          <el-switch
            v-model="scope.row[column?.prop]"
            :active-color="column?.activeColor"
            :inactive-color="column?.inactiveColor"
            :active-text="column?.activeText"
            :inactive-text="column?.inactiveText"
            @change="column?.change(scope?.row)"
          ></el-switch>
        </template>
        <!-- 点击跳转 -->
        <template v-else-if="column?.type == '2'">
          <div
            @click="column.click && column.click(scope?.row)"
            :class="column?.jumpClassName"
          >
            {{ scope?.row[column?.prop] }}
          </div>
        </template>
        <!-- 默认 -->
        <template v-else>
          <span :style="column?.styles"
            >{{ scope?.row[column?.prop] }}{{ column?.text }}</span
          >
        </template>
      </template>
      <!-- 操作列的处理 -->
      <template v-else>
        <!-- 普通类型按钮  显示全部-->
        <div :class="column?.btnClass" v-if="column?.type == 0">
          <el-button
            v-for="(item, index) in column?.btnList"
            :key="index + item?.name"
            :type="item?.type"
            :class="item?.className"
            :plain="item?.plain"
            :round="item?.round"
            :circle="item?.circle"
            @click="item.click && item.click(scope?.row)"
            :size="item?.size"
            :style="item?.style"
            >{{ item?.name }}</el-button
          >
        </div>
        <!-- 权限按钮 -->
        <div :class="column?.btnClass" v-if="column?.type == 1">
          <div
            v-for="(item, index) in column?.btnList"
            :key="index + item?.name"
            style="display: inline-block"
          >
            <div v-if="item.has">
              <div v-if="item?.name == '产权变更'">
                <template v-if="scope.row.handOver">
                  <el-button
                    :type="item?.type"
                    :class="item?.className"
                    :plain="item?.plain"
                    :round="item?.round"
                    :circle="item?.circle"
                    @click="item.click && item.click(scope?.row)"
                    :size="item?.size"
                    v-if="
                      column?.list[scope?.row?.[column.prop]]?.findIndex(
                        (items) => items == item?.name
                      ) > -1
                    "
                    v-has="item.hasName"
                  >
                    {{ item?.name }}
                  </el-button>
                </template>
              </div>
              <div v-else>
                <el-button
                  :type="item?.type"
                  :class="item?.className"
                  :plain="item?.plain"
                  :round="item?.round"
                  :circle="item?.circle"
                  @click="item.click && item.click(scope?.row)"
                  :size="item?.size"
                  v-if="
                    column?.list[scope?.row?.[column.prop]]?.findIndex(
                      (items) => items == item?.name
                    ) > -1
                  "
                  v-has="item.hasName"
                >
                  {{ item?.name }}
                </el-button>
              </div>
            </div>
            <div v-else>
              <el-button
                :type="item?.type"
                :class="item?.className"
                :plain="item?.plain"
                :round="item?.round"
                :circle="item?.circle"
                @click="item.click && item.click(scope?.row)"
                :size="item?.size"
                v-if="
                  column?.list[scope?.row?.[column.prop]]?.findIndex(
                    (items) => items == item?.name
                  ) > -1
                "
              >
                {{ item?.name }}
              </el-button>
            </div>
          </div>
        </div>
        <!-- 普通类型按钮  单个根据权限隐藏-->
        <!-- <div class="flex" :class="column?.btnClass" style="width: 100%;" v-if="column?.type == 2">
          <div v-for="(item, index) in column?.btnList" :key="index + item?.name" style="margin-right:5px">
            {{ scope?.row[column?.key] }} {{ column?.value }}
            <el-button :type="item?.type" v-if="item?.flag || scope?.row[column?.key] == column?.value"
              :plain="item?.plain" :round="item?.round" :circle="item?.circle"
              @click="item.click && item.click(scope?.row)" :size="item?.size" :style="item?.style">{{ item?.name
              }}</el-button>
          </div>
        </div> -->
      </template>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "columnView",
  props: {
    column: { require: true },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  margin: 0px;
}
</style>
