<template>
  <div>
    <div class="operate-title">操作记录</div>
    <div class="operate">
      <div
        :class="{ 'operate-chunk': true, line: index != dataList.length - 1 }"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div class="pic-circle">
          <el-image
            style="width: 20px; height: 20px"
            :src="require('@/assets/components/ActionRecord/icon.png')"
          ></el-image>
        </div>
        <div class="pic-text-box">
          <div class="pic-text">{{ item.operateType }}</div>
          <div class="unit-box">{{ item.unit }} - {{ item.commitName }}</div>
          <div>{{ item.createTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logList } from "@/api/liftingEquipment/index.js";
export default {
  name: "ActionRecord",
  props: {
    type: {
      required: true,
    },
    logId: {
      required: true,
    },
  },
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this._logList();
  },
  methods: {
    async _logList() {
      const res = await logList({ type: this.type, logId: this.logId });
      if (res.success) {
        this.dataList = res.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.operate {
  padding: 10px 20px 20px;
  background: #fff;
  border: 1px solid #e4e4e4;
  opacity: 1;
  border-radius: 0px;
}

.operate-title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #333;
}
.pic-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex: none;
}

.operate-chunk {
  margin-top: 10px;
  display: flex;
}

.pic-text {
  height: 18px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.pic-text-box {
  margin-left: 10px;
}

.line {
  position: relative;
}

.line:after {
  content: "";
  display: block;
  height: 110%;
  width: 2px;
  background-color: #13ce66;
  position: absolute;
  top: 15px;
  left: 9px;
}
.unit-box {
  margin-bottom: 4px;
}
</style>
